import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';

interface InputSearchProps {
    icon: string;
    placeholder: string;
    setSearch: (search: string) => void;
}

// method that will update the search after 1 second
const InputSearch = (props: InputSearchProps) => {

    const [searchCriteria, setSearchCriteria] = useState("");

    useEffect(() => {
        if (searchCriteria.length > 0) {
            const timeoutId = setTimeout(() => {
                //props.fetchData(1, searchCriteria);
                props.setSearch(searchCriteria);
            }, 1000);
            return () => clearTimeout(timeoutId);
        } else {
            //props.fetchData(1);
            props.setSearch("");
        }
        // eslint-disable-next-line
    }, [searchCriteria]);

    return (
        <Input fluid icon={props.icon} 
            placeholder={props.placeholder} 
            value={searchCriteria}
            onChange={(e: any) => setSearchCriteria(e.target.value)} />
    );
};

export default InputSearch;
