import React from 'react';
import { Button, Grid, GridColumn, Header, HeaderSubheader } from 'semantic-ui-react';

interface PageHeaderProps {
    title: string;
    subtitle?: string;
    saveButton?: boolean;
    isSubmitting?: boolean;
    resetButton?: boolean;
    actionComponents?: React.ReactNode;
}

const PageHeader = (props: PageHeaderProps) => {
    return (
        <Grid style={{ marginBottom: 0 }}>
            <GridColumn floated="left" width={10}>
                <Header as="h1">
                    {props.title}
                    {props.subtitle && <HeaderSubheader>{props.subtitle}</HeaderSubheader>}    
                </Header>
            </GridColumn>
            <GridColumn floated="right" width={5}>
                {props.actionComponents && props.actionComponents}
                {props.saveButton && (<Button floated="right" positive type="submit" disabled={props.isSubmitting}>Guardar</Button>)}
                {props.resetButton && (<Button floated="right" type="reset">Cancelar</Button>)}
            </GridColumn>
        </Grid>
    );
};

export default PageHeader;
