import React, { useEffect, useState } from "react";
import { Button, Form, FormField, FormGroup, Grid, GridColumn, Header, Input, Segment, Select } from "semantic-ui-react";
import User from "../../../types/entities/User";
import { createUser, updateUser } from "../../../services/users.service";
import { toast } from "react-toastify";
import { USER_COLOR_OPTIONS, USER_ROLES_OPTIONS } from "../../../utils/dropdownOptions";

interface Props {
    userId: string | undefined;
    user: User | null;
    setUser: (user: User | null) => void;
}

interface RequestData {
    role?: string;
    username?: string;
    password?: string;
    calendarColorId?: string;
}

const UserAccountForm = ({ userId, user, setUser }: Props) => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [ requestData, setRequestData ] = useState<RequestData | undefined>(undefined);

    useEffect(() => {
        setRequestData({
            role: user?.role || "",
            username: user?.username || "",
            password: "",
            calendarColorId: user?.calendarColorId || "",
        });
    }, [ user ]);

    const handleChange = (e: any, { name, value }: any) => {
        console.log(name, value);
        setRequestData({
            ...requestData,
            [name]: value ?? "",
        } as RequestData);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {

            if (!userId) {
                throw new Error("El prospecto no puede ser undefined.");
            }

            if (requestData?.username && requestData.username.length < 8) {
                throw new Error("El nombre de usuario debe de tener al menos 8 caracteres, sin espacios ni caracteres especiales.");
            }

            if (user?.id) {
                const response = await updateUser(userId, requestData);

                if (response?.status === 200) {
                    setUser(response.data);
                    toast.success("Informacion del usuario actualizada exitosamente.");
                } else {
                    throw new Error("El usuario no ha sido actualizado exitosamente, favor de contactar al area de TI");
                }
            } else {
                const response = await createUser(requestData);

                if (response?.status === 201) {
                    setUser(response.data);
                    toast.success("Informacion del usuario guardada exitosamente.");
                } else {
                    throw new Error("El usuario no ha sido creado exitosamente, favor de contactar al area de TI");
                }
            }
            
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid>
                <GridColumn>
                    <Segment attached='top' color="grey" style={{ marginTop: 15 }}>
                        <Grid>
                            <GridColumn style={{ paddingTop: 5, paddingBottom: 5 }} verticalAlign="middle">
                                <Header as='h4' floated="left" style={{ marginTop: 8, marginBottom: 7 }}>Datos del usuario</Header>
                                { user?.id ? (
                                    <Button floated="right" color="yellow" type="submit" style={{ margin: 0 }} disabled={isSubmitting}>Actualizar</Button>
                                ): (
                                    <Button floated="right" primary type="submit" style={{ margin: 0 }} disabled={isSubmitting}>Guardar</Button>
                                )} 
                            </GridColumn>
                        </Grid>
                    </Segment>
                    <Segment attached='bottom'>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-role">Rol</label>}
                                options={USER_ROLES_OPTIONS}
                                placeholder="Selecciona una opcion"
                                name="role"
                                value={requestData?.role || ""}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-role"}}
                                clearable
                            />
                            <FormField
                                control={Input}
                                label="Usuario"
                                placeholder="Nombre de usuario sin espacios"
                                name="username"
                                value={requestData?.username || ""}
                                onChange={ handleChange }
                                required
                            />
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-color">Color en calendario</label>}
                                options={USER_COLOR_OPTIONS}
                                placeholder="Selecciona una opcion"
                                name="calendarColorId"
                                value={requestData?.calendarColorId || ""}
                                onChange={handleChange}
                                search
                                searchInput={{id: "form-select-control-color"}}
                                clearable
                            />
                        </FormGroup>
                    </Segment>
                </GridColumn>
            </Grid>
        </Form>
    );
};

export default UserAccountForm;
