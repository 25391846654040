import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PageHeader from "../../components/PageHeader";
import SearchClientModal from "../../components/SearchClientModal";
import RootTemplate from "../../components/RootTemplate";
import { useAppContext } from "../../hooks/AppProvider";
import Guarantee from "../../types/entities/Guarantee";
import { assignClientToGuarantee, getGuaranteeById } from "../../services/guarantees.service";
import GuaranteeDetailsForm from "./forms/GuaranteeDetailsForm";
import GuaranteeClientPersonForm from "./forms/GuaranteeClientPersonForm";
import GuaranteeAddressForm from "./forms/GuaranteeAddressForm";

const AdjustmentsForm = () => {
    
    const { updateParentState } = useAppContext();
    const [ guarantee, setGuarantee ] = useState<Guarantee | null>(null);
    const { guaranteeId } = useParams();

    const fetchGuaranteeById = async (id: string) => {
        try {
            const response = await getGuaranteeById(id);
            if (response?.status === 200) {
                setGuarantee(response.data);
            } else {
                throw new Error("Error inesperado al obtener el ajuste");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const hanldeClientSelected = async (clientId: string) => {
        updateParentState({ loading: true });
        try {
            if (!guaranteeId) {
                throw new Error("No se ha seleccionado un ajuste");
            }
            const response = await assignClientToGuarantee(guaranteeId, clientId);
            if (response?.status === 200) {
                setGuarantee(response.data);
                toast.success("La informacion del cliente seleccionado ha sido asignada al ajuste.");
            } else {
                throw new Error("Ha ocurrido un error al asignar el cliente al ajuste.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    useEffect(() => {
        if (guaranteeId !== undefined) {
            fetchGuaranteeById(guaranteeId);
        }
    }, [ guaranteeId ]);

    return (
        <RootTemplate>
              
            <PageHeader
                title={guarantee ? `Editar ajuste` : "Crear nuevo ajuste"}
                actionComponents={
                    <>
                        <SearchClientModal 
                            disabled={guarantee?.client?.id ? true : false} 
                            onClientSelected={hanldeClientSelected}
                        />
                    </>
                }
            /> 

            <GuaranteeDetailsForm
                guarantee={guarantee}
                setGuarantee={setGuarantee}
            />

            { guarantee && (
                <>
                    <GuaranteeClientPersonForm 
                        guarantee={guarantee} 
                        setGuarantee={setGuarantee}
                    />

                    <GuaranteeAddressForm 
                        guarantee={guarantee}
                        setGuarantee={setGuarantee}
                    />
                </>
            )}

        </RootTemplate>
    );
};

export default AdjustmentsForm;
