import React, { useEffect, useState } from "react";
import { Button, Form, FormField, FormGroup, Grid, GridColumn, Header, Icon, Input, Segment, TextArea } from "semantic-ui-react";
import { toast } from "react-toastify";
import { createOrder, updateOrder } from "../../../services/orders.service";
import Guarantee from "../../../types/entities/Guarantee";
import { uploadGuaranteePaymentProof } from "../../../services/guarantees.service";
import GuaranteeFilesModal from "../modals/GuaranteeFilesModal";

interface Props {
    guarantee: Guarantee | null;
    setGuarantee: (guarantee: Guarantee | null) => void;
}

interface RequestData {
    cause?: string;
    moneyRemaining?: string;
}

const GuaranteeDetailsForm = ({ guarantee, setGuarantee }: Props) => {

    const [loading, setLoading] = useState(false);

    const [ requestData, setRequestData ] = useState<RequestData>({
        cause: "",
        moneyRemaining: "",
    });

    useEffect(() => {
        setRequestData({
            cause: guarantee?.cause || "",
            moneyRemaining: formatMoney(guarantee?.moneyRemaining.toString()) || "",
        });
    }, [ guarantee ]);

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value ?? "",
        } as RequestData);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        try {

            // clean remaining money after creating the order
            requestData.moneyRemaining = requestData.moneyRemaining?.replace(/[^0-9.,]/g, '').replace(/,/g, '');

            if (guarantee?.id) {
                const response = await updateOrder(guarantee.id, requestData);

                if (response?.status === 200) {
                    setGuarantee(response.data);
                    toast.success("El ajuste ha sido actualizado exitosamente.");
                } else {
                    throw new Error("El ajuste no ha sido actualizado exitosamente, favor de contactar al area de TI");
                }
            } else {
                const response = await createOrder(requestData);

                if (response?.status === 201) {
                    setGuarantee(response.data);
                    toast.success("El ajuste ha sido creado exitosamente.");
                } else {
                    throw new Error("El ajuste no ha sido creado exitosamente, favor de contactar al area de TI");
                }
            }
            
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };
    
    const handlePaymentProof = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        try {

            if (guarantee?.id === undefined) {
                throw new Error("El id de la garantía no puede ser undefined.");
            }

            const file = event.target.files[0];
            const formData = new FormData();
            formData.append( "file", file, file.name );

            const response = await uploadGuaranteePaymentProof(guarantee.id, formData);

            if (response?.status === 200) {
                setGuarantee(response?.data);
                toast.success("El comprobante de pago se ha cargado con exito.");
            } else {
                throw new Error("El comprobante de pago no se ha cargado con exito, favor de contactar al area de TI");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeMoneyRemaining = (e: any, { name, value }: any) => {
        const newValue = value.replace(/[^0-9.,]/g, '').replace(/,/g, '');
        if (newValue.length <= 10) {
            setRequestData({
                ...requestData,
                [name]: formatMoney(newValue) ?? "",
            } as RequestData);
        }
    };

    // this method receives a string of numbers and returns a formatted string
    const formatMoney = (value: string | undefined) => {
        if (!value) {
            return "";
        }
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Grid>
                <GridColumn>
                    <Segment attached='top' color="grey" style={{ marginTop: 15 }}>
                        <Grid>
                            <GridColumn style={{ paddingTop: 5, paddingBottom: 5 }} verticalAlign="middle">
                                <Header as='h4' floated="left" style={{ marginTop: 8, marginBottom: 7 }}>Datos del ajuste</Header>
                                { guarantee?.id ? (
                                    <Button floated="right" color="yellow" type="submit" style={{ margin: 0 }} isSubmitting={loading}>Actualizar</Button>
                                ): (
                                    <Button floated="right" primary type="submit" style={{ margin: 0 }} isSubmitting={loading}>Guardar</Button>
                                )} 
                            </GridColumn>
                        </Grid>
                    </Segment>
                    <Segment attached='bottom' loading={loading}>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Input}
                                label={<label htmlFor="form-select-control-installer">Saldo por liquidar</label>}
                                placeholder="Si no hay saldo pendiente, poner 0"
                                name="moneyRemaining"
                                value={requestData.moneyRemaining}
                                onChange={handleChangeMoneyRemaining}
                                required
                            />

                            <FormField
                                clearable
                                control={TextArea}
                                label={<label htmlFor="form-select-control-payment">Causa de la garantia</label>}
                                placeholder="Selecciona una opcion"
                                name="cause"
                                value={requestData.cause}
                                onChange={handleChange}                                                            
                                required
                            />
                        </FormGroup>
                        
                        { guarantee?.proofPayment === null ? (
                            <Button secondary type="button">
                                <label htmlFor="uploadFile">
                                    Subir comprobante de pago
                                </label>
                                <input id="uploadFile" style={{display: "none"}} type="file"
                                    onChange={event => handlePaymentProof(event)} />
                            </Button>
                        ): (
                            <Button color="orange" icon href={guarantee?.proofPayment} target="_blank">
                                <Icon name="download" /> Ver comprobante de pago
                            </Button>
                        )}

                        { guarantee && (
                            <GuaranteeFilesModal guarantee={guarantee} />
                        )}

                    </Segment>
                </GridColumn>
            </Grid>
        </Form>
    );
};

export default GuaranteeDetailsForm;
