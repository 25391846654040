import { setHours, setMinutes } from 'date-fns';
import React, { SyntheticEvent, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';

interface Props {
    id: string;
    submit: (event: SyntheticEvent, prospectId: string, appointmentDate: Date | null) => void;
}

const RescheduleAppointmentModal = (props: Props) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ appointmentDate, setAppointmentDate ] = useState<Date | null>(null);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="green" icon size="mini" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="refresh" />
                            </Button>
                        }
                        content='Reagendar cita cancelada'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="refresh" content="Reagendar cita" />
                <ModalContent>
                    <p>
                        Está a punto de reagendar la cita previamente cancelada para el prospecto. 
                        Si está seguro de realizar esta acción, por favor selecciona la nueva fecha para esta cita.
                    </p>

                    <div className="ui form">
                        <FormField>
                            <label>Fecha y hora de la visita</label>
                            <DatePicker
                                showTimeSelect
                                isClearable
                                minDate={new Date()}
                                minTime={setHours(setMinutes(new Date(),59),7)}
                                maxTime={setHours(setMinutes(new Date(),59),21)}
                                dateFormat="d-MM-yyyy h:mm aa"
                                selected={appointmentDate}
                                placeholderText="Fecha de la visita"
                                onChange={(date: Date | null) => setAppointmentDate(date)}
                                wrapperClassName="date-picker-custom-parent"
                                className="date-picker-custom"
                            />
                        </FormField>
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => props.submit(event, props.id, appointmentDate) } disabled={!appointmentDate}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default RescheduleAppointmentModal;
