import React, { SyntheticEvent, useState } from 'react';
import { Button, Checkbox, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup, TextArea } from 'semantic-ui-react';

interface DoTracingModalProps {
    id: string;
    currentTraceNumber: number;
    submit: (event: SyntheticEvent, prospectId: string, currentTraceNumber: number, tracingNotes: string) => void;
}

const DoTracingModal = (props: DoTracingModalProps) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ tracingDone, setTracingDone ] = useState(false);
    const [ tracingNotes, setTracingNotes ] = useState<string>("");

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            
            <Modal
                closeOnDimmerClick
                cloeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="blue" icon size="mini" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="phone" />
                            </Button>
                        }
                        content='Realizar seguimiento'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="phone" content="Realizar seguimiento" />
                <ModalContent>
                    <p>Es momento de realizar el seguimiento a este prospecto. Una vez que hayas realizdo el seguimiento correspondiente debes de marcarlo como "Seguimiento realizado".</p>

                    <div className="ui form">
                        <FormField>
                            <Checkbox
                                label="Confirmo que el seguimiento ha sido realizado."
                                checked={tracingDone}
                                onChange={ (event, data) => setTracingDone(tracingDone ? false : true) }
                            />
                        </FormField>

                        <FormField
                            control={TextArea}
                            label="Notas adicionales"
                            placeholder="Añade notas adicionales para el seguimiento..."
                            name="description"
                            value={tracingNotes}
                            onChange={ (e: any, data: { value: React.SetStateAction<string>; }) => setTracingNotes(data.value) }
                        />
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => props.submit(event, props.id, props.currentTraceNumber, tracingNotes) } disabled={!tracingDone}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default DoTracingModal;
