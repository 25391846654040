import React from 'react';
import { DT_CUSTOM_STYLES, DT_PAGINATION_COMPONENT_OPTIONS } from '../utils/constants';
import { Container, Loader, Message } from 'semantic-ui-react';
import DataTable from 'react-data-table-component';

interface LuxonDataTableProps {
    columns: any,
    data: any,
    loading: boolean,
    totalRows: number,
    conditionalRowStyles?: any,
    handlePageChange: (page: number) => void
    handlePerRowsChange: (newPerPage: number, page: number) => void,
};

const LuxonDataTable = (props: LuxonDataTableProps) => {

    const noDataComponent = (
        <Container fluid>
            <Message icon='info' header="Información de la sección." 
                content="Aún no se ha creado o no existe ningún registro para este criterio de búsqueda." />
        </Container>
    );

    const loaderComponent = (
        <Loader active inline style={{margin: "10px"}}>Cargando...</Loader>    
    );

    return (
        <DataTable
            columns={props.columns}
            data={props.data}
            customStyles={DT_CUSTOM_STYLES}
            conditionalRowStyles={props.conditionalRowStyles}
            noDataComponent={noDataComponent}
            striped
            pagination
            paginationServer
            progressPending={props.loading}
            progressComponent={loaderComponent}
            paginationComponentOptions={DT_PAGINATION_COMPONENT_OPTIONS}
            paginationTotalRows={props.totalRows}
            onChangeRowsPerPage={props.handlePerRowsChange}
            onChangePage={props.handlePageChange} 
        />
    );
};

export default LuxonDataTable;