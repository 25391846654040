import React, { useEffect, useState } from "react";
import {
    Button,
    Container,
    Divider,
    Form,
    FormField,
    FormGroup,
    Grid,
    GridColumn,
    Header,
    Input,
    Select,
} from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import { getProducts } from "../../services/products.service";
import { createRequest } from "../../services/requests.service";
import { USER_ROLE_ADVISOR } from "../../utils/constants";
import { getUsers } from "../../services/users.service";
import { toast } from "react-toastify";
import { AGE_RANGES_OPTIONS, GENDER_OPTIONS, NUEVO_LEON_CITIES_OPTIONS } from "../../utils/dropdownOptions";


// TODO: delete this component, it is unused
const OrdersCreate = () => {
    const [advisors, setAdvisors] = useState([]);
    const [products, setProducts] = useState([]);
    const [isAdvisorsFetched, setIsAdvisorsFetched] = useState(false);
    const [isProductsFetched, setIsProductsFetched] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [requestData, setRequestData] = useState({
        name: "",
        lastName: "",
        address: "",
        postalCode: "",
        city: "",
        phone: "",
        email: "",
        age: "",
        gender: "",
        moneyRemaining: "",
        advisorId: "",
        productId: "",
    });

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value,
        });
    };

    const handleChangePhone = (e: any, { name, value }: any) => {
        if (value.length <= 10) {
            setRequestData({
                ...requestData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            if (requestData.phone.length !== 10) {
                throw new Error("El telefono no es valido, favor de verificar que sean 10 digitos sin espacios.");
            }

            // clean remaining money after creating the order
            requestData.moneyRemaining = requestData.moneyRemaining.replace(/[^0-9.,]/g, '').replace(/,/g, '');

            const response = await createRequest(requestData);

            if (response?.status === 201) {
                window.location.href = `/orders/${response?.data.id}/details`;
            } else {
                throw new Error("El pedido no ha sido creado exitosamente, favor de contactar al area de TI");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const fetchAdvisors = async () => {
        try {
            const response = await getUsers(100, 0, USER_ROLE_ADVISOR);
            console.log(response);
            if (!response?.data.content) {
                return;
            }
            const advisorsList = response?.data.content.map(
                (advisor: any) => {
                    return {
                        key: advisor.id,
                        value: advisor.id,
                        text: advisor.person?.fullName ?? "Sin nombre",
                    };
                }
            );
            setAdvisors(advisorsList);
            setIsAdvisorsFetched(true);
        } catch (error) {
            console.error("Error al obtener la lista de asesores:", error);
        }
    };

    useEffect(() => {
        fetchAdvisors();
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await getProducts(100, 0);
                const productsList = response?.data.content.map(
                    (product: any) => {
                        return {
                            key: product["id"],
                            value: product["id"],
                            text: `${product["sku"]} - ${product["name"]}`,
                        };
                    }
                );
                setProducts(productsList);
                setIsProductsFetched(true);
            } catch (error) {
                toast.error("Error al obtener la lista de productos");
            }
        };
        fetchProducts();
    }, []);

    // add a comma every 3 digits
    const handleChangeMoneyRemaining = (e: any, { name, value }: any) => {
        const newValue = value.replace(/[^0-9.,]/g, '').replace(/,/g, '');
        const formattedValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (newValue.length <= 10) {
            setRequestData({
            ...requestData,
            [name]: formattedValue,
            });
        }
    };

    return (
        <>
            <Navbar />
            <Container style={{ marginTop: "7em" }}>
                <Form
                    error
                    onSubmit={handleSubmit}
                    onReset={() => window.location.href = "/requests"}
                    loading={!isAdvisorsFetched && !isProductsFetched}
                >
                    <Grid>
                        <GridColumn floated="left" width={5}>
                            <Header as="h1">Nuevo pedido</Header>
                        </GridColumn>
                        <GridColumn floated="right" width={5}>
                            <Button floated="right" positive type="submit" disabled={isSubmitting}>
                                Guardar
                            </Button>
                            <Button floated="right" type="reset">Cancelar</Button>
                        </GridColumn>
                    </Grid>

                    <Divider />

                    <FormGroup widths="equal">
                        <FormField
                            control={Select}
                            label="Asesor"
                            options={advisors}
                            placeholder="Selecciona una opcion"
                            name="advisorId"
                            value={requestData.advisorId}
                            onChange={handleChange}
                            required
                        />
                        <FormField
                            control={Select}
                            label="Producto"
                            options={products}
                            placeholder="Selecciona una opcion"
                            name="productId"
                            value={requestData.productId}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>

                    <FormGroup widths="equal">
                        <FormField
                            control={Input}
                            label="Nombre"
                            placeholder="Nombre(s)"
                            name="name"
                            value={requestData.name}
                            onChange={handleChange}
                            required
                        />
                        <FormField
                            control={Input}
                            label="Apellidos"
                            placeholder="Apellidos"
                            name="lastName"
                            value={requestData.lastName}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>

                    <FormField
                        control={Input}
                        label="Direccion de instalacion"
                        placeholder="ej. Bristol 1234, Faisanes 2 sector"
                        name="address"
                        value={requestData.address}
                        onChange={handleChange}
                        required
                    />

                    <FormGroup widths="equal">
                        <FormField
                            control={Input}
                            type="number"
                            label="Codigo postal"
                            placeholder="ej. 67123"
                            name="postalCode"
                            value={requestData.postalCode}
                            onChange={handleChange}
                        />
                        <FormField
                            control={Select}
                            label={<label htmlFor="form-select-control-city">Ciudad</label>}
                            options={NUEVO_LEON_CITIES_OPTIONS}
                            placeholder="Selecciona una opcion"
                            name="city"
                            value={requestData.city}
                            onChange={handleChange}
                            required
                            search
                            searchInput={{ id: 'form-select-control-city' }}
                        />
                    </FormGroup>
                    <FormGroup widths="equal">
                        <FormField
                            control={Input}
                            type="number"
                            label="Telefono (Whatsapp)"
                            placeholder="ej. 8341490185"
                            name="phone"
                            value={requestData.phone}
                            onChange={handleChangePhone}
                            required
                        />
                        <FormField
                            control={Input}
                            label="Email"
                            placeholder="ej. example@gmail.com"
                            name="email"
                            value={requestData.email}
                            onChange={handleChange}
                        />
                        <FormField
                            control={Select}
                            label="Rango de edad"
                            options={AGE_RANGES_OPTIONS}
                            placeholder="Selecciona una opcion"
                            name="age"
                            value={requestData.age}
                            onChange={handleChange}
                        />
                        <FormField
                            control={Select}
                            label="Sexo"
                            options={GENDER_OPTIONS}
                            placeholder="Selecciona una opcion"
                            name="gender"
                            value={requestData.gender}
                            onChange={handleChange}
                            required
                        />
                    </FormGroup>
                    
                    <FormGroup widths="equal">
                        <FormField
                            control={Input}
                            label="Saldo por liquidar"
                            placeholder="0.00"
                            name="moneyRemaining"
                            value={requestData.moneyRemaining}
                            onChange={handleChangeMoneyRemaining}
                            required
                        />
                    </FormGroup>
                </Form>
            </Container>
        </>
    );
};

export default OrdersCreate;
