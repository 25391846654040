import React, { SyntheticEvent, useState } from 'react';
import { Button, Checkbox, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';

interface SaleNotCompletedModalProps {
    id: string;
    submit: (event: SyntheticEvent, prospectId: string, saleStatus: string) => void;
}

const SaleNotCompletedModal = (props: SaleNotCompletedModalProps) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ saleCompleted, setSaleCompleted ] = useState(false);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="red" icon size="mini" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="cancel" />
                            </Button>
                        }
                        content='Venta no concretada'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="cancel" content="Venta no concretada" />
                <ModalContent>
                    
                    <p>¿Estas seguro que la venta no ha sido concretada?. Una vez que hayas realizado este paso, no podras volver atras.</p>
                        
                    <FormField>
                        <Checkbox
                            label="Confirmo que la venta no ha sido conrectada."
                            checked={saleCompleted}
                            onChange={ (event, data) => setSaleCompleted(saleCompleted ? false : true) }
                        />
                    </FormField>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => props.submit(event, props.id, "SALE_NOT_COMPLETED") } disabled={!saleCompleted}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default SaleNotCompletedModal;
