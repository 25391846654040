import React, { useEffect, useState } from "react";
import { Button, Form, FormField, FormGroup, Grid, GridColumn, Header, Icon, Input, Segment, Select } from "semantic-ui-react";
import { getUsers } from "../../../services/users.service";
import { USER_ROLE_ADVISOR } from "../../../utils/constants";
import { getProducts } from "../../../services/products.service";
import { toast } from "react-toastify";
import Order from "../../../types/entities/Order";
import { createOrder, updateOrder } from "../../../services/orders.service";
import { updatePaymentProof } from "../../../services/requests.service";

interface Props {
    order: Order | null;
    setOrder: (order: Order | null) => void;
}

interface RequestData {
    advisorId?: string;
    productId?: string;
    moneyRemaining?: string;
}

const OrderRequestForm = ({ order, setOrder }: Props) => {

    const [loading, setLoading] = useState(false);

    const [advisors, setAdvisors] = useState([]);
    const [products, setProducts] = useState([]);

    const [ requestData, setRequestData ] = useState<RequestData>({
        advisorId: "",
        productId: "",
        moneyRemaining: "",
    });

    useEffect(() => {
        setRequestData({
            advisorId: order?.advisor?.id || "",
            productId: order?.product?.id || "",
            moneyRemaining: formatMoney(order?.moneyRemaining.toString()) || "",
        });
    }, [ order ]);

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value ?? "",
        } as RequestData);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        try {

            // clean remaining money after creating the order
            requestData.moneyRemaining = requestData.moneyRemaining?.replace(/[^0-9.,]/g, '').replace(/,/g, '');

            if (order?.id) {
                const response = await updateOrder(order.id, requestData);

                if (response?.status === 200) {
                    setOrder(response.data);
                    toast.success("El pedido ha sido actualizado exitosamente.");
                } else {
                    throw new Error("El pedido no ha sido actualizado exitosamente, favor de contactar al area de TI");
                }
            } else {
                const response = await createOrder(requestData);

                if (response?.status === 201) {
                    setOrder(response.data);
                    toast.success("El pedido ha sido creado exitosamente.");
                } else {
                    throw new Error("El pedido no ha sido creado exitosamente, favor de contactar al area de TI");
                }
            }
            
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };
    
    const handlePaymentProof = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        try {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append( "file", file, file.name );

            const response = await updatePaymentProof(order?.id, formData);

            if (response?.status === 200) {
                setOrder(response?.data);
                toast.success("El comprobante de pago se ha cargado con exito.");
            } else {
                throw new Error("El comprobante de pago no se ha cargado con exito, favor de contactar al area de TI");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchAdvisors = async () => {
        try {
            const response = await getUsers(100, 0, USER_ROLE_ADVISOR);
            if (!response?.data.content) {
                return;
            }
            const advisorsList = response?.data.content.map(
                (advisor: any) => {
                    return {
                        key: advisor.id,
                        value: advisor.id,
                        text: advisor.person?.fullName ?? "Sin nombre",
                    };
                }
            );
            setAdvisors(advisorsList);
        } catch (error) {
            toast.error("Error al obtener la lista de asesores");
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await getProducts(100, 0);
            const productsList = response?.data.content.map(
                (product: any) => {
                    return {
                        key: product["id"],
                        value: product["id"],
                        text: `${product["sku"]} - ${product["name"]}`,
                    };
                }
            );
            setProducts(productsList);
        } catch (error) {
            toast.error("Error al obtener la lista de productos");
        }
    };

    useEffect(() => {
        fetchAdvisors();
        fetchProducts();
    }, []);

    const handleChangeMoneyRemaining = (e: any, { name, value }: any) => {
        const newValue = value.replace(/[^0-9.,]/g, '').replace(/,/g, '');
        if (newValue.length <= 10) {
            setRequestData({
                ...requestData,
                [name]: formatMoney(newValue) ?? "",
            } as RequestData);
        }
    };

    // this method receives a string of numbers and returns a formatted string
    const formatMoney = (value: string | undefined) => {
        if (!value) {
            return "";
        }
        console.log("formatMoney: ",value);
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Grid>
                <GridColumn>
                    <Segment attached='top' color="grey" style={{ marginTop: 15 }}>
                        <Grid>
                            <GridColumn style={{ paddingTop: 5, paddingBottom: 5 }} verticalAlign="middle">
                                <Header as='h4' floated="left" style={{ marginTop: 8, marginBottom: 7 }}>Datos del pedido</Header>
                                { order?.id ? (
                                    <Button floated="right" color="yellow" type="submit" style={{ margin: 0 }} disabled={loading}>Actualizar</Button>
                                ): (
                                    <Button floated="right" primary type="submit" style={{ margin: 0 }} disabled={loading}>Guardar</Button>
                                )} 
                            </GridColumn>
                        </Grid>
                    </Segment>
                    <Segment attached='bottom' loading={loading}>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-advisor">Asesor</label>}
                                options={advisors}
                                placeholder="Selecciona una opcion"
                                name="advisorId"
                                value={requestData?.advisorId || ""}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-advisor"}}
                                clearable
                            />
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-advisor">Producto de interes</label>}
                                options={products}
                                placeholder="Selecciona una opcion"
                                name="productId"
                                value={requestData?.productId || ""}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-advisor"}}
                                clearable
                            />
                            <FormField
                                control={Input}
                                label="Saldo por liquidar"
                                placeholder="0.00"
                                name="moneyRemaining"
                                value={requestData?.moneyRemaining}
                                onChange={handleChangeMoneyRemaining}
                                required
                            />
                        </FormGroup>
                        
                        { order?.paymentProof === null ? (
                            <div>
                                <label htmlFor="uploadFile" className="ui secondary button">
                                    Subir comprobante de pago
                                </label>
                                <input id="uploadFile" style={{display: "none"}} type="file"
                                    onChange={event => handlePaymentProof(event)} />
                            </div>
                        ): (
                            <Button color="orange" icon href={order?.paymentProof} target="_blank">
                                <Icon name="download" /> Ver comprobante de pago
                            </Button>
                        )}

                    </Segment>
                </GridColumn>
            </Grid>
        </Form>
    );
};

export default OrderRequestForm;
