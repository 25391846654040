import React, { useEffect, useState } from 'react';
import { Checkbox, Label } from 'semantic-ui-react';
import StatusFiltersOption from '../types/models/StatusFiltersOption';

interface StatusFiltersProps {
    options: Array<StatusFiltersOption>;
    setStatusFilters: (status: string[]) => void;
}

const StatusFilters = (props: StatusFiltersProps) => {

    const [statusFiltersCriteria, setStatusFiltersCriteria] = useState<string[]>([]);

    const handleStatusFilters = (status: string) => {
        statusFiltersCriteria.includes(status) ? statusFiltersCriteria.splice(statusFiltersCriteria.indexOf(status), 1) : statusFiltersCriteria.push(status);
        setStatusFiltersCriteria([...statusFiltersCriteria]);
    }

    useEffect(() => {
        props.setStatusFilters(statusFiltersCriteria);
        // eslint-disable-next-line
    }, [statusFiltersCriteria]);

    return (
        <div style={{ marginTop: 15 }}>
            { props.options && props.options.map(item => (
                <Label color={ item.color}><Checkbox label={<label style={{ color: "white" }}>{item.name}</label>} 
                    onChange={() => handleStatusFilters(item.statusKey)} /></Label>
            )) }
        </div>
    );
};

export default StatusFilters;
