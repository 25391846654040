import React, { SyntheticEvent, useState } from 'react';
import { Button, FormField, Header, Icon, Input, Modal, ModalActions, ModalContent, Popup, Select } from 'semantic-ui-react';
import { createUser } from '../../../services/users.service';
import { toast } from 'react-toastify';
import { USER_COLOR_OPTIONS, USER_ROLES_OPTIONS } from '../../../utils/dropdownOptions';

const CreateUserModal = () => {

    const [ openModal, setOpenModal ] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const [ requestData, setRequestData ] = useState({
        role: "",
        username: "",
        password: "",
        calendarColorId: "",
    });

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value,
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {

            if (requestData.password && requestData.password.length < 8) {
                throw new Error("La contraseña debe de tener al menos 8 caracteres.");
            }

            if (requestData.username && requestData.username.length < 8) {
                throw new Error("El nombre de usuario debe de tener al menos 8 caracteres, sin espacios ni caracteres especiales.");
            }

            const response = await createUser(requestData);
            if (response?.status === 201) {
                window.location.href = `/users/${response.data.id}/form`;
            } else {
                throw new Error("El usuario no ha podido ser creado, favor de contactar al area de TI");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button floated='right' color="blue" onClick={ (event) => handleOpenModal(event) }>
                                Nuevo usuario
                            </Button>
                        }
                        content='Crear un nuevo usuario'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="user" content="Nuevo usuario" />
                <ModalContent>
                    <div className="ui form">

                        <FormField
                            control={Select}
                            label={<label htmlFor="form-select-control-role">Rol</label>}
                            options={USER_ROLES_OPTIONS}
                            placeholder="Selecciona una opcion"
                            name="role"
                            value={requestData.role}
                            onChange={handleChange}
                            required
                            search
                            searchInput={{id: "form-select-control-role"}}
                            clearable
                        />

                        <FormField
                            control={Input}
                            label="Usuario"
                            placeholder="ej. nombre.apellido"
                            name="username"
                            value={requestData.username}
                            onChange={ handleChange }
                            required
                        />

                        <FormField
                            control={Input}
                            label="Contraseña"
                            placeholder="Añade una contraseña segura"
                            name="password"
                            value={requestData.password}
                            onChange={ handleChange }
                            required
                        />

                        <FormField
                            control={Select}
                            label={<label htmlFor="form-select-control-color">Color</label>}
                            options={USER_COLOR_OPTIONS}
                            placeholder="Selecciona una opcion"
                            name="calendarColorId"
                            value={requestData.calendarColorId || ""}
                            onChange={handleChange}
                            search
                            searchInput={{id: "form-select-control-color"}}
                            clearable
                        />

                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (e) => handleSubmit(e) } disabled={isSubmitting}>
                        <Icon name="checkmark" /> Guardar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default CreateUserModal;
