import React, { SyntheticEvent, useState } from 'react';
import { Button, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup, TextArea } from 'semantic-ui-react';

interface CancelAppointmentModalProps {
    id: string;
    submit: (event: SyntheticEvent, prospectId: string, cancelationCause: string) => void;
}

const CancelAppointmentModal = (props: CancelAppointmentModalProps) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ cancellationCause, setCancellationCause ] = useState<string>("");

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="red" icon size="mini" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="calendar" />
                            </Button>
                        }
                        content='Cancelar cita'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="phone" content="Cancelar cita" />
                <ModalContent>
                    <p>Está a punto de cancelar la cita previamente agendada para el prospecto. Si está seguro de realizar esta acción, por favor agregue algunas notas indicando la causa de la cancelación.</p>

                    <div className="ui form">
                        <FormField
                            control={TextArea}
                            label="Causa de la cancelación"
                            placeholder="Añade notas adicionales para el seguimiento..."
                            name="description"
                            value={cancellationCause}
                            onChange={ (e: any, data: { value: React.SetStateAction<string>; }) => setCancellationCause(data.value) }
                        />
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => props.submit(event, props.id, cancellationCause) } disabled={!cancellationCause}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default CancelAppointmentModal;
