import React, { useEffect, useState } from "react";
import { Card, Container, Label } from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import Prospect from "../../types/entities/Prospect";
import LuxonDataTable from "../../components/LuxonDataTable";
import { formatDateTime } from "../../utils/datetimeUtils";
import InputSearch from "../../components/InputSearch";
import PageHeader from "../../components/PageHeader";
import { getCompletedProspects } from "../../services/prospects.service";
import { PROSPECTS_STATUSES } from "../../utils/constants";
import { toast } from "react-toastify";

const CompletedList = () => {
	const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [search, setSearch] = useState("");

	const columns = [
        {
            name: "Fecha en que se completo",
            selector: (row: Prospect) => formatDateTime(row.saleCompletedDate),
        },
        {
            name: "Nombre del prospecto",
            selector: (row: Prospect) => row.person?.fullName,
        },
        {
            name: "Producto",
            selector: (row: Prospect) => row.product.name,
        },
        {
            name: "Estatus",
            selector: (row: Prospect) => <Label color={PROSPECTS_STATUSES[row.status].color}>{PROSPECTS_STATUSES[row.status].name}</Label>,
        },
    ];

	const fetchData = async (page: number, search?: string) => {
        setLoading(true);
        try {
			const response = await getCompletedProspects(perPage, page-1, "saleCompletedDate,desc", search);
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            toast.error("Error al obtener la informacion");
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
			const response = await getCompletedProspects(newPerPage, page-1, "saleCompletedDate,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            toast.error("Error al obtener la informacion");
        } finally {
            setLoading(false);
        }        
    };

    useEffect(() => {
        fetchData(1, search);
        // eslint-disable-next-line
    }, [ , search]);

	return (
		<>
			<Navbar />
			<Container className="container-custom">
                <PageHeader title="Prospectos completados" subtitle="Clientes potenciales" />
                <InputSearch icon="search" placeholder="Búsqueda por nombre de prospecto..." setSearch={setSearch} />

				<Card fluid>
                    <LuxonDataTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Card>
			</Container>
		</>
	);
};

export default CompletedList;
