import React, { useEffect, useState } from "react";
import {
    Container,
    Form,
    FormField,
    FormGroup,
    Input,
    Segment,
    Select,
    TextArea,
} from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import { getProducts } from "../../services/products.service";
import { createProspect } from "../../services/prospects.service";
import { getUsers } from "../../services/users.service";
import PageHeader from "../../components/PageHeader";
import { setHours, setMinutes } from "date-fns";
import DatePicker from "react-datepicker";
import { USER_ROLE_ADVISOR } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const GuaranteeCreate = () => {

    let { orderId } = useParams();
    
    const [advisors, setAdvisors] = useState([]);
    const [products, setProducts] = useState([]);
    const [isAdvisorsFetched, setIsAdvisorsFetched] = useState(false);
    const [isProductsFetched, setIsProductsFetched] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [appointmentDate, setAppointmentDate] = useState<Date | null>(null);

    const [requestData, setRequestData] = useState({
        firstName: "",
        lastName: "",
        address: "",
        phone: "",
        userId: "",
        productId: "",
        notes: "",
        appointment: "",
    });

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value,
        });
    };

    const handleChangePhone = (e: any, { name, value }: any) => {
        if (value.length <= 10) {
            setRequestData({
                ...requestData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            if (requestData.phone.length !== 10) {
                throw new Error("El telefono no es valido, favor de verificar que sean 10 digitos sin espacios.");
            }

            if (!appointmentDate) {
                throw new Error("Favor de seleccionar una fecha de la cita");
            } else {
                requestData.appointment = appointmentDate.toISOString();
            }

            const response = await createProspect(requestData);

            if (response?.status === 201) {
                window.location.href = `/prospects`;
            } else {
                throw new Error("El prospecto no ha sido creado exitosamente, favor de contactar al area de TI");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        const fetchAdvisors = async () => {
            try {
                const response = await getUsers(100, 0, USER_ROLE_ADVISOR);
                console.log(response);
                if (!response?.data.content) {
                    return;
                }
                const advisorsList = response?.data.content.map(
                    (advisor: any) => {
                        return {
                            key: advisor["id"],
                            value: advisor["id"],
                            text: `${advisor["name"]}`,
                        };
                    }
                );
                setAdvisors(advisorsList);
                setIsAdvisorsFetched(true);
            } catch (error) {
                toast.error("Error al obtener la lista de asesores");
            }
        };
        fetchAdvisors();
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await getProducts(100, 0);
                const productsList = response?.data.content.map(
                    (product: any) => {
                        return {
                            key: product["id"],
                            value: product["id"],
                            text: `${product["sku"]} - ${product["name"]}`,
                        };
                    }
                );
                setProducts(productsList);
                setIsProductsFetched(true);
            } catch (error) {
                toast.error("Error al obtener la lista de productos");
            }
        };
        fetchProducts();
    }, []);

    return (
        <>
            <Navbar />
            <Container style={{ marginTop: "7em" }}>
                <Form
                    error
                    onSubmit={handleSubmit}
                    onReset={() => (window.location.href = `/orders/${orderId}/detail`)}
                    loading={!isAdvisorsFetched && !isProductsFetched}
                >
                    <PageHeader
                        title="Nueva garantia"
                        subtitle="#Numero de orden"
                        saveButton
                        resetButton
                        isSubmitting={isSubmitting}
                    />

                    <Segment color="grey">
                        <FormGroup widths="equal">
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-advisor">Asesor</label>}
                                options={advisors}
                                placeholder="Selecciona una opcion"
                                name="userId"
                                value={requestData.userId}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-advisor"}}
                                clearable
                            />
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-product">Producto de interes</label>}
                                options={products}
                                placeholder="Selecciona una opcion"
                                name="productId"
                                value={requestData.productId}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-product"}}
                                clearable
                            />
                        </FormGroup>

                        <FormGroup widths="equal">
                            <FormField
                                control={Input}
                                label="Nombre"
                                placeholder="Nombre(s)"
                                name="firstName"
                                value={requestData.firstName}
                                onChange={handleChange}
                                required
                            />
                            <FormField
                                control={Input}
                                label="Apellidos"
                                placeholder="Apellidos"
                                name="lastName"
                                value={requestData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>

                        <FormField
                            control={Input}
                            label="Direccion de la visita"
                            placeholder="ej. Bristol 1234, Faisanes 2 sector"
                            name="address"
                            value={requestData.address}
                            onChange={handleChange}
                            required
                        />

                        <FormGroup widths="equal">
                            <FormField
                                control={Input}
                                type="number"
                                label="Telefono (Whatsapp)"
                                placeholder="ej. 8341490185"
                                name="phone"
                                value={requestData.phone}
                                onChange={handleChangePhone}
                                required
                            />

                            <FormField required>
                                <label>Fecha y hora de visita</label>
                                <DatePicker
                                    showTimeSelect
                                    isClearable
                                    minDate={new Date()}
                                    minTime={setHours(setMinutes(new Date(), 59),7)}
                                    maxTime={setHours(setMinutes(new Date(), 59),21)}
                                    dateFormat="d-MM-yyyy h:mm aa"
                                    selected={appointmentDate}
                                    placeholderText="Fecha de instalación"
                                    onChange={(date: Date | null) =>setAppointmentDate(date)}
                                    wrapperClassName="date-picker-custom-parent"
                                    className="date-picker-custom"
                                />
                            </FormField>
                        </FormGroup>

                        <FormField
                            control={TextArea}
                            label="Notas adicionales"
                            placeholder="ej. Tambien se mostro interesado en X producto."
                            name="notes"
                            value={requestData.notes}
                            onChange={handleChange}
                        />
                    </Segment>
                </Form>
            </Container>
        </>
    );
};

export default GuaranteeCreate;
