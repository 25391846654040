import React, { SyntheticEvent, useEffect, useState } from "react";
import { Button, Card, Container, Icon } from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import Prospect from "../../types/entities/Prospect";
import LuxonDataTable from "../../components/LuxonDataTable";
import { hoursPassedSince, formatDateTime } from "../../utils/datetimeUtils";
import InputSearch from "../../components/InputSearch";
import PageHeader from "../../components/PageHeader";
import { cancelAppointmentById, getAssignments, markProspectQuotedById, markProspectVisitedById } from "../../services/prospects.service";
import ProspectVisitedModal from "./modals/ProspectVisitedModal";
import QuoteSentModal from "./modals/QuoteSentModal";
import CancelAppointmentModal from "./modals/CancelAppointmentModal";
import { toast } from "react-toastify";
import CreateProspectModal from "./modals/CreateProspectModal";
import { useAuth } from "../../hooks/AuthProvider";

const AssignmentsList = () => {
	const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [search, setSearch] = useState("");

    const auth = useAuth();

	const columns = [
        {
            name: "Fecha de visita",
            selector: (row: Prospect) => formatDateTime(row.appointment),
        },
        {
            name: "Nombre del prospecto",
            selector: (row: Prospect) => row.person?.fullName ?? "",
        },
        {
            name: "Producto",
            selector: (row: Prospect) => row.product.name,
        },
        {
            name: "Asignado por",
            selector: (row: Prospect) => row.createdBy?.person?.fullName || '',
        },
        {
            name: "Acciones",
            button: true,
            cell: (row: Prospect) => (
                <>
                    {row.alreadyVisited ? (
                        <QuoteSentModal id={row.id} submit={handleQuoted} />
                    ) : (
                        <>
                            <CancelAppointmentModal id={row.id} submit={handleCancelationAppointment} />
                            <ProspectVisitedModal
                                id={row.id}
                                submit={handleVisited}
                                disabled={!row.person || !row.appointmentAddress}
                            />
                            {row.createdBy.id === auth.userData?.id && (
                                <Button color="yellow" icon size="mini" href={`prospects/${row.id}/form`}>
                                    <Icon name="pencil" />
                                </Button>
                            )}
                            
                        </>
                    )}
                </>
            ),
        },
    ];

    const conditionalRowStyles = [
        {
            when: (row: any) => row.alreadyVisited === false && (hoursPassedSince(row.appointment) > 24),
            style: {
                backgroundColor: "#ffefbf",
                "&:hover": {
                    cursor: "pointer",
                },
            },
        },
        {
            when: (row: any) => (row.alreadyVisited === true) && row.alreadyVisitedDate && (hoursPassedSince(row.alreadyVisitedDate) > 24),
            style: {
                backgroundColor: "#f4c0c0",
                "&:hover": {
                    cursor: "pointer",
                },
            },
        },
    ];

	const fetchData = async (page: number, search?: string) => {
        setLoading(true);
        try {
			const response = await getAssignments(perPage, page-1, search, "appointment,asc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            toast.error("Error al obtener la informacion de las asignaciones");
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
			const response = await getAssignments(newPerPage, page-1, "", "appointment,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            toast.error("Error al obtener la informacion de las asignaciones");
        } finally {
            setLoading(false);
        }        
    };

    useEffect(() => {
        fetchData(1, search);
        // eslint-disable-next-line
    }, [ , search]);

    const handleCancelationAppointment = async (event: SyntheticEvent, prospectId: string | undefined, cancellationCause: string) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await cancelAppointmentById(prospectId, cancellationCause);
            if (response?.status === 200) {
                fetchData(1, search);
                toast.success("La cita fue cancelada con exito.");
            } else {
                throw new Error("Ha ocurrido un error al cancelar la cita para este prospecto.");
            }
        } catch (error: any) {
            toast.error(error.message);
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleVisited = async (event: SyntheticEvent, prospectId: string | undefined) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await markProspectVisitedById(prospectId);
            if (response?.status === 200) {
                fetchData(1, search);
                toast.success("El prospecto fue marcado como visitado exitosamente.");
            } else {
                throw new Error("Ha ocurrido un error al marcar como visitado el prospecto.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleQuoted = async (event: SyntheticEvent, prospectId: string | undefined) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await markProspectQuotedById(prospectId);
            if (response?.status === 200) {
                fetchData(1, search);
                toast.success("El prospecto ha sido cotizado exitosamente.");
            } else {
                throw new Error("Ha ocurrido un error al marcar como cotizado el prospecto.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

	return (
		<>
			<Navbar />
			<Container className="container-custom">

                <PageHeader 
                    title="Asignaciones" 
                    subtitle="Clientes potenciales" 
                    actionComponents={<CreateProspectModal />} 
                />

                <InputSearch 
                    icon="search" 
                    placeholder="Búsqueda por nombre de prospecto..." 
                    setSearch={setSearch} 
                />

				<Card fluid>
                    <LuxonDataTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        conditionalRowStyles={conditionalRowStyles}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Card>
                
			</Container>
		</>
	);
};

export default AssignmentsList;
