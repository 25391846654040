import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button, Divider, Header, Icon, Image, ImageGroup, Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import Guarantee from '../../../types/entities/Guarantee';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../hooks/AppProvider';
import { getGuaranteeFilesByGuaranteeId, uploadGuaranteeFile } from '../../../services/guarantees.service';
import GuaranteeFile from '../../../types/entities/GuaranteeFile';

interface Props {
    guarantee: Guarantee;
    fluid?: boolean; 
    isOpen?: boolean;
}

const GuaranteeFilesModal = ({ guarantee, fluid, isOpen }: Props) => {

    const { updateParentState } = useAppContext();
    const [ openModal, setOpenModal ] = useState(isOpen);

    const [ guaranteeFiles, setGuaranteeFiles ] = useState<GuaranteeFile[]>([]);

    // Función para obtener los datos con parámetros
    const fetchGuaranteeFiles = async (guaranteeId: string) => {
        updateParentState({ loading: true });
        try {
            if (!guaranteeId) {
                return;
            }
            const response = await getGuaranteeFilesByGuaranteeId(guaranteeId);
            setGuaranteeFiles(response?.data);
        } catch (error) {
            toast.error("Error al obtener el listado de clientes");
        } finally {
            updateParentState({ loading: false });
        }
    };

    useEffect(() => {
        if (openModal) {
            fetchGuaranteeFiles(guarantee?.id);
        }
        // eslint-disable-next-line
    }, [openModal]);

    useEffect(() => {
        setOpenModal(isOpen);
    }, [isOpen]);

    const handleGuaranteeFile = async (event: any) => {
        event.preventDefault();
        updateParentState({ loading: true });
        try {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append( "file", file, file.name );

            const response = await uploadGuaranteeFile(guarantee?.id, formData);

            if (response?.status === 201) {
                setGuaranteeFiles([...guaranteeFiles, response?.data]);
                toast.success("Evidencia de la garantía se ha cargado con exito.");
            } else {
                throw new Error("La evidencia de la garantía no se ha cargado con exito, favor de contactar al area de TI");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        if (guaranteeFiles?.length >= 2) {
            setOpenModal(false);    
        } else {
            toast.error("Debes de cargar minimo 2 evidencias antes de proseguir");
        }
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Button icon fluid={fluid} primary onClick={ (event) => handleOpenModal(event) }>
                        <Icon name="photo" /> Evidencias (fotos)
                    </Button>
                } >
                <Header icon="photo" content="Evidencia de la garantía" />
                <ModalContent>

                    <p style={{color: "red"}}>
                        Solo se permite cargar imágenes*<br/>
                        Deberas de subir minimo 2 evidencias y maximo 5*
                    </p>

                    <Button secondary disabled={guaranteeFiles?.length >= 6}>
                        <label htmlFor="uploadFile">
                            Cargar evidencia
                        </label>
                        <input id="uploadFile" style={{display: "none"}} type="file"
                            onChange={event => handleGuaranteeFile(event)} />
                    </Button>

                    <Divider />

                    <ImageGroup size='tiny'>
                        {guaranteeFiles?.map((file: GuaranteeFile) => (
                            <Image 
                                key={file.id}
                                src={file.fileName} 
                                href={file.fileName}
                                target='_blank' 
                            />
                        ))}
                    </ImageGroup>

                </ModalContent>
                <ModalActions>
                    <Button positive onClick={ (event) => handleCancelModal(event) }>Aceptar</Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default GuaranteeFilesModal;
