import React, { SyntheticEvent, useState } from 'react';
import { Button, Header, Icon, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';
import Prospect from '../../../types/entities/Prospect';

interface Props {
    prospect: Prospect;
}

const ProspectCancelationCauseModal = (props: Props) => {

    const [ openModal, setOpenModal ] = useState(false);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="teal" icon size="mini" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="question circle" />
                            </Button>
                        }
                        content='Causa de la cancelacion'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="delete calendar" content="Causa de la cancelación" />
                <ModalContent>
                    <Header as="h4" color='red'> { `${ props.prospect.cancellationCause }` }</Header>
                </ModalContent>
                <ModalActions>
                    <Button color="green" onClick={ (event) => handleCancelModal(event) }>
                        Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default ProspectCancelationCauseModal;
