import React, { SyntheticEvent, useEffect, useState } from "react";
import {
    Button,
    Card,
    Container,
    Icon,
} from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import LuxonDataTable from "../../components/LuxonDataTable";
import User from "../../types/entities/User";
import { getUsers } from "../../services/users.service";
import PageHeader from "../../components/PageHeader";
import CreateUserModal from "./modals/CreateUserModal";
import InputSearch from "../../components/InputSearch";
import { toast } from "react-toastify";
import { USER_ROLES } from "../../utils/constants";

const UsersList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [search, setSearch] = useState("");

    const columns = [
        {
            name: "Rol",
            selector: (row: User) => {
                const role = Object.values(USER_ROLES).find((value) => value.key === row.role);
                return role ? role.label : "Rol no valido";
            },
        },
        {
            name: "Usuario",
            selector: (row: User) => row.username,
        },
        {
            name: "Nombre",
            selector: (row: User) => row.person?.fullName ?? "",
        },
        {
            name: "Telefono",
            selector: (row: User) => row.person?.phone,
        },
        {
            name: "Acciones",
            button: true,
            cell: (row: User) => (
                <>
                    <Button color="yellow" icon size="mini" href={`/users/${row.id}/form`}>
                        <Icon name="pencil" />
                    </Button>
                    <Button color="red" icon size="mini" onClick={ (event) => handleOpenDelete(event, row.id) } content={row.id}>
                        <Icon name="trash" />
                    </Button>
                </>
            ),
        },
    ];

    const fetchData = async (page: number, search?: string) => {
        setLoading(true);
        try {
            const response = await getUsers(perPage, page-1, "", search);
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            toast.error("Error al obtener el listado de usuarios");
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
            const response = await getUsers(newPerPage, page-1);
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }        
    };

    useEffect(() => {
        fetchData(1, search);
        // eslint-disable-next-line
    }, [ , search]);

    const handleOpenDelete = (event: SyntheticEvent, advisorId: string) => {
        event.preventDefault();
    };

    return (
        <>
            <Navbar />
            <Container className="container-custom">

                <PageHeader 
                    title="Usuarios" 
                    subtitle="Listado de usuarios"
                    actionComponents={ <CreateUserModal /> } 
                />

                <InputSearch icon="search" placeholder="Búsqueda por nombre de prospecto..." setSearch={setSearch} />

                <Card fluid>
                    <LuxonDataTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Card>
            </Container>
        </>
    );
};

export default UsersList;
