import React, { SyntheticEvent, useState } from "react";
import Guarantee from "../../../types/entities/Guarantee";
import { Button, Divider, Header, Icon, Image, ImageGroup, Modal, ModalActions, ModalContent, Popup } from "semantic-ui-react";
import GuaranteeFile from "../../../types/entities/GuaranteeFile";
import { formatDateTime } from "../../../utils/datetimeUtils";
import { formatMoney } from "../../../utils/numberUtils";
import Address from "../../../types/entities/Address";

interface Props {
    guarantee: Guarantee
};

const GuaranteeDetailModal = (props: Props) => {

    const [ openModal, setOpenModal ] = useState(false);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    const formatAddress = (address: Address | null) => {
        if (!address) return "";
        return `${address?.street} ${address?.exteriorNumber} ${address?.interiorNumber}, ${address?.neighborhood}, ${address?.city}, ${address?.state}, Cp. ${address?.postalCode}`;
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button secondary icon size="mini" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="search" />
                            </Button>
                        }
                        content='Detalle de la garantia'
                        position='bottom center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="list" content="Detalle de la garantía" />
                <ModalContent>

                    <p><b>Fecha de creación:</b> {`${formatDateTime(props.guarantee?.createdAt)}`}</p>
                    <p><b>Nombre del cliente:</b> {props.guarantee?.client?.person?.fullName}</p>
                    <p><b>Dirección del cliente:</b> {formatAddress(props.guarantee?.address)}</p>
                    <p><b>Saldo pendiente:</b> ${formatMoney(props.guarantee?.moneyRemaining)}</p>
                    <p><b>Causa de la garantía:</b> {props.guarantee?.cause}</p>

                    { props.guarantee?.proofPayment && (
                        <Button icon href={props.guarantee?.proofPayment} target='_blank'>
                            <Icon name="attach" /> Comprobante de pago
                        </Button>
                    )}

                    { props.guarantee?.order && (
                        <Button icon href={`orders/${props.guarantee?.order?.id}/details`} target='_blank'>
                            <Icon name="cart" /> Orden de compra
                        </Button>
                    )}

                    <Divider />

                    {props.guarantee?.guaranteeFiles === null ? (
                        <p style={ { color: "red" } }>*No se ha cargado ninguna evidencia de la garantía.</p>
                    ): (
                        <>
                        <p><b>Evidencia cargada</b><br />
                            *Para ver las evidencias, haga click en la imagen</p>
                        <ImageGroup size='tiny'>
                            {props.guarantee?.guaranteeFiles?.map((file: GuaranteeFile) => (
                                <Image 
                                    key={file.id}
                                    src={file.fileName} 
                                    href={file.fileName}
                                    target='_blank' 
                                />
                            ))}
                        </ImageGroup>
                        </>
                    )}

                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cerrar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default GuaranteeDetailModal;