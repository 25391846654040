import React, { SyntheticEvent, useState } from 'react';
import { Button, Checkbox, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';

interface ProspectVisitedModalProps {
    id: string;
    disabled?: boolean;
    submit: (event: SyntheticEvent, prospectId: string | undefined) => void;
}

const ProspectVisitedModal = (props: ProspectVisitedModalProps) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ prospectVisited, setProspectVisited ] = useState(false);

    const handleOpen = (event: SyntheticEvent, prospectId: string) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancel = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancel(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="green" icon size="mini" onClick={ (event) => handleOpen(event, props.id) } disabled={props.disabled}>
                                <Icon name="home" />
                            </Button>
                        }
                        content='Marcar prospecto visitado'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="home" content="Prospecto visitado" />
                <ModalContent>
                    <p> Estas seguro de marcar este prospecto como visitado. Esta accion no se puede deshacer.</p>

                    <div className="ui form">
                        <FormField>
                            <Checkbox
                                label="Confirmo que el cliente ya fue visitado."
                                checked={prospectVisited}
                                onChange={ (event, data) => setProspectVisited(prospectVisited ? false : true) }
                            />
                        </FormField>
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancel(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => props.submit(event, props.id) } disabled={!prospectVisited}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default ProspectVisitedModal;
