import React, { createContext, useState, useContext, ReactNode } from "react";

type Props = {
    children?: ReactNode;
}

type ParentState = {
    loading: boolean;
};

// Define la forma del contexto
interface AppContextProps {
    parentState: ParentState;
    updateParentState: (state: ParentState) => void;
}

// Crea el contexto con un valor inicial vacío
const AppContext = createContext<AppContextProps | undefined>(undefined);

// Hook personalizado para usar el contexto fácilmente
export const useAppContext = () => {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("useAppContext must be used within AppProvider");
    }
    return context;
};

// Proveedor del contexto
export const AppProvider = ({ children }: Props) => {
    const [parentState, setParentState] = useState({
        loading: false
    });

    const updateParentState = (newState: ParentState) => {
        setParentState((prevState: ParentState) => ({
          ...prevState,
          ...newState,
        }));
      };

    return (
        <AppContext.Provider value={{ parentState, updateParentState }}>
            {children}
        </AppContext.Provider>
    );
};
