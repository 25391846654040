import React, { SyntheticEvent, useState } from 'react';
import { Button, Checkbox, FormField, Header, Icon, Input, Modal, ModalActions, ModalContent, Popup, TextArea } from 'semantic-ui-react';
import Order from '../../../types/entities/Order';
import { toast } from 'react-toastify';
import { updateOrderStatusToProjectUnfinished } from '../../../services/orders.service';
import { useAppContext } from '../../../hooks/AppProvider';

interface Props {
    id: string | undefined;
    setOrder: (order: Order) => void;
}

const UpdateStatusToProjectUnfinishedModal = (props: Props) => {

    const { updateParentState } = useAppContext();
    
    const [ openModal, setOpenModal ] = useState(false);
    const [ confirmChecked, setConfirmChecked ] = useState(false);

    const [ file, setFile ] = useState<Blob | undefined>(undefined);
    const [ fileName, setFileName ] = useState("");

    const [ requestData, setRequestData ] = useState({
        moneyRemaining: "",
        cause: "",
    });

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value ?? "",
        });
    };

    const handleChangeMoneyRemaining = (e: any, { name, value }: any) => {
        const newValue = value.replace(/[^0-9.,]/g, '').replace(/,/g, '');
        if (newValue.length <= 10) {
            setRequestData({
                ...requestData,
                [name]: formatMoney(newValue) ?? "",
            });
        }
    };

    // this method receives a string of numbers and returns a formatted string
    const formatMoney = (value: string | undefined) => {
        if (!value) {
            return "";
        }
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const handleProjectUnfinished = async (event: SyntheticEvent, orderId: string | undefined) => {
        event.preventDefault();
        updateParentState({ loading: true });
        try {

            if (orderId === undefined) {
                throw new Error("El id del pedido no puede ser undefined.");
            }

            if (file === undefined) {
                throw new Error("Favor de seleccionar un comprobante de pago.");
            }

            const formData = new FormData();
            formData.append( "file", file, fileName );
            // clean remaining money after creating the order
            formData.append( "moneyRemaining", requestData.moneyRemaining?.replace(/[^0-9.,]/g, '').replace(/,/g, '') );
            formData.append( "cause", requestData.cause );

            const response = await updateOrderStatusToProjectUnfinished(orderId, formData);
            if (response?.status === 200) {
                props.setOrder(response?.data);
                toast.success("El pedido ha sido movido a proyecto inconcluso exitosamente.");
                setOpenModal(false);
            } else {
                throw new Error("Ha ocurrido un error al mover el pedido a proyecto inconcluso.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    const handlePaymentProof = async (event: any) => {
        event.preventDefault();
        try {
            const file = event.target.files[0];
            setFile(file);
            setFileName(file.name);
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="orange" floated='right' icon onClick={ (event) => handleOpenModal(event) } >
                                Mover pedido a "Proyecto inconcluso"
                            </Button>
                        }
                        content='Mover pedido a "Proyecto inconcluso"'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="check square" content='Mover pedido a "Proyecto inconcluso"' />
                <ModalContent>
                    <p>
                        Recuerda que una vez que modifiques el estatus ya no se podra regresar al estatus
                        anterior y se enviaran las notificaciones correspondientes al cliente.
                    </p>

                    <div className="ui form">

                        <FormField
                            control={Input}
                            label={<label htmlFor="form-select-control-installer">Nuevo saldo por liquidar</label>}
                            placeholder="Selecciona una opcion"
                            name="moneyRemaining"
                            value={requestData.moneyRemaining}
                            onChange={handleChangeMoneyRemaining}
                            required
                        />

                        <FormField
                            clearable
                            control={TextArea}
                            label={<label htmlFor="form-select-control-payment">Causa de la garantia</label>}
                            placeholder="Selecciona una opcion"
                            name="cause"
                            value={requestData.cause}
                            onChange={handleChange}                                                            
                            required
                        />

                        <FormField>
                            <label htmlFor="payment-proof">Adjuntar comprobante de pago</label>
                            <input id='payment-proof' type='file' accept="*" onChange={event => handlePaymentProof(event)} />
                        </FormField>

                        <FormField>
                            <Checkbox
                                label="Confirmo que quiero actualizar el estatus del pedido."
                                checked={confirmChecked}
                                onChange={ (event, data) => setConfirmChecked(confirmChecked ? false : true) }
                            />
                        </FormField>
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => handleProjectUnfinished(event, props.id) } disabled={!confirmChecked}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default UpdateStatusToProjectUnfinishedModal;
