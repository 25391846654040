import React, { SyntheticEvent, useEffect, useState } from "react";
import {
    Button,
    Card,
    Icon,
    Label,
    Popup,
} from "semantic-ui-react";
import { getRequests } from "../../services/requests.service";
import { ORDERS_STATUSES } from "../../utils/constants";
import Order from "../../types/entities/Order";
import LuxonDataTable from "../../components/LuxonDataTable";
import { daysPassedSince, formatDateTime, hoursPassedSince } from "../../utils/datetimeUtils";
import InputSearch from "../../components/InputSearch";
import PageHeader from "../../components/PageHeader";
import CreateOrderFromProspectModal from "./modals/CreateOrderFromProspectModal";
import RootTemplate from "../../components/RootTemplate";
import { useAppContext } from "../../hooks/AppProvider";
import { deleteOrder } from "../../services/orders.service";
import { toast } from "react-toastify";
import DeleteOrderModal from "./modals/DeleteOrderModal";

const RequestsList = () => {
    
    const { updateParentState } = useAppContext();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [search, setSearch] = useState("");

    const columns = [
        {
            name: "Folio",
            button: true,
            selector: (row: Order) => <Label horizontal>{`#${row.invoice}`}</Label>,
        },
        {
            name: "Fecha de compra",
            selector: (row: Order) => formatDateTime(row.orderDate),
        },
        {
            name: "Cliente",
            selector: (row: Order) => row.client?.person?.fullName ?? "",
        },
        {
            name: "Dias transcurridos",
            selector: (row: Order) => `${daysPassedSince(row.orderDate)} dias`,
        },
        {
            name: "Producto",
            selector: (row: Order) => row.product.name,
        },
        {
            name: "Asesor",
            selector: (row: Order) => `${row.advisor?.person?.fullName}`,
        },
        {
            name: "Estatus",
            cell: (row: Order) => (
                <>
                    { row.status === null ? (
                        <Label color="olive">Pedido incompleto</Label>
                    ): (
                        <Label color={ORDERS_STATUSES[row.status].color}>{ORDERS_STATUSES[row.status].name}</Label>
                    )}
                </>
            ),
        },
        {
            name: "Acciones",
            button: true,
            style: { justifyContent: "right", paddingRight: "16px", paddingLeft: "16px" },
            cell: (row: Order) => (
                <>
                    { hoursPassedSince(row.orderDate) > 24  && (
                        <Popup content='Pedido con mas de 24 horas en "Validando pedido"' 
                        trigger={<Icon link name="warning sign" color="red" size='large' />} />
                    )}

                    { row.status === null ? (
                        <>
                            <DeleteOrderModal id={row.id} submit={(event) => handleDelete(event, row.id)} />
                            <Button color="yellow" icon size="mini" href={`orders/${row.id}/form`}>
                                <Icon name="pencil" />
                            </Button>
                        </>
                    ): (
                        <Button secondary icon size="mini" href={`orders/${row.id}/details`}>
                            <Icon name="search" />
                        </Button>
                    )}
                </>
            )
        },
    ];

	const fetchData = async (page: number, search?: string) => {
        setLoading(true);
        try {
			const response = await getRequests(perPage, page-1, search, "createdAt,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
			const response = await getRequests(newPerPage, page-1, "", "createdAt,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }        
    };

    const handleDelete = async (event: SyntheticEvent, orderId: string) => {
        event.preventDefault();
        updateParentState({ loading: true });
        try {
            const response = await deleteOrder(orderId);
            if (response?.status === 204) {
                fetchData(1, search);
                toast.success("El pedido ha sido eliminado con exito.");
            } else {
                throw new Error("Ha ocurrido un error al eliminar el pedido.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    useEffect(() => {
        fetchData(1);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData(1, search);
        // eslint-disable-next-line
    }, [search]);

    return (
        <RootTemplate>

            <PageHeader 
                title="Pedidos" 
                subtitle="Pedidos pendientes y en validación" 
                actionComponents={ <CreateOrderFromProspectModal /> } 
            />

            <InputSearch 
                icon="search" 
                placeholder="Búsqueda por nombre de cliente..." 
                setSearch={setSearch}
            />

            <Card fluid>
                <LuxonDataTable 
                    columns={columns}
                    data={data}
                    loading={loading}
                    totalRows={totalRows}
                    handlePageChange={handlePageChange}
                    handlePerRowsChange={handlePerRowsChange}
                />
            </Card>

        </RootTemplate>
    );
};

export default RequestsList;
