import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Button, FormField, FormGroup, Header, Icon, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';
import LuxonDataTable from '../../../components/LuxonDataTable';
import Prospect from '../../../types/entities/Prospect';
import { getCompletedProspectsForOrder } from '../../../services/prospects.service';
import InputSearch from '../../../components/InputSearch';
import { formatDateTime } from '../../../utils/datetimeUtils';
import CreateOrderFromScratchModal from './CreateOrderFromScratchModal';
import { toast } from 'react-toastify';
import { useAppContext } from '../../../hooks/AppProvider';
import { createOrderFromProspect } from '../../../services/orders.service';

const CreateOrderFromProspectModal = () => {

    const { updateParentState } = useAppContext();
    
    const [ loading, setLoading ] = useState(false);
    const [ openModal, setOpenModal ] = useState(false);

    const [ perPage, setPerPage ] = useState(10);
    const [ totalRows, setTotalRows ] = useState(0);
    const [ , setCurrentPage ] = useState(1);
    const [ data, setData ] = useState<Prospect[]>([]);
    
    const [ searchByFullName, setSearchByFullName ] = useState("");
    const [ searchByPhone, setSearchByPhone ] = useState("");
    
    const columns = [
        {
            name: "Nombre",
            selector: (row: Prospect) => row.person?.fullName,
        },
        {
            name: "Fecha de visita",
            selector: (row: Prospect) => formatDateTime(row.appointment),
        },
        {
            name: "Producto de interes",
            selector: (row: Prospect) => row.product?.name,
        },
        {
            name: "Crear pedido",
            button: true,
            style: { justifyContent: "right", paddingRight: "16px", paddingLeft: "16px" },
            cell: (row: Prospect) => (
                <>
                    <Button color='green' icon size="mini" onClick={ (event) => handleProspectSelected(event, row.id) }>
                        <Icon name="plus circle" />
                    </Button>
                </>
            )
        },
    ];

    const fetchData = useCallback(async (page: number, rowsPerPage: number, fullName: string, phone: string) => {
        setLoading(true);
        try {
            const response = await getCompletedProspectsForOrder(rowsPerPage, page-1, "createdAt,desc", fullName, phone);
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            toast.error("Error al obtener el listado de clientes");
        } finally {
            setLoading(false);
        }
    }, []);

    // Handlers that call the fetchData method
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        fetchData(page, perPage, searchByFullName, searchByPhone);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
        fetchData(page, newPerPage, searchByFullName, searchByPhone);
    };

    useEffect(() => {
        fetchData(1, perPage, searchByFullName, searchByPhone);
        // eslint-disable-next-line
    }, [searchByFullName, searchByPhone]);

    useEffect(() => {
        if (openModal) {
            fetchData(1, perPage, searchByFullName, searchByPhone);
        }
    }, [openModal, perPage, searchByFullName, searchByPhone, fetchData]);

    const handleProspectSelected = async (event: SyntheticEvent, prospectId: string) => {
        event.preventDefault();
        updateParentState({ loading: true });
        try {
            if (!prospectId) {
                throw new Error("No se ha seleccionado un prospecto");
            }
            const response = await createOrderFromProspect(prospectId);
            if (response?.status === 201) {
                window.location.href = `/orders/${response.data.id}/form`;
            } else {
                throw new Error("Ha ocurrido un error al crear el pedido desde el prospecto seleccionado.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
        setOpenModal(false);
    };

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                centered={false}
                closeOnDimmerClick
                closeIcon={true}
                size="large"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button floated='right' color="blue" onClick={ (event) => handleOpenModal(event) }>
                                Crear nuevo pedido
                            </Button>
                        }
                        content='Crear un nuevo pedido'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="plus circle" content="Crear desde venta concretada de prospecto" />
                <ModalContent>
                    <>
                        <div className="ui form">
                            <FormGroup widths={2}>
                                <FormField>
                                    <InputSearch 
                                        icon="phone" 
                                        placeholder="Búsqueda por telefono..." 
                                        setSearch={setSearchByPhone}
                                    />
                                </FormField>

                                <FormField>
                                    <InputSearch 
                                        icon="user" 
                                        placeholder="Búsqueda por nombre..." 
                                        setSearch={setSearchByFullName}
                                    />
                                </FormField>
                            </FormGroup>
                        </div>

                        <LuxonDataTable
                            columns={columns}
                            data={data}
                            loading={loading}
                            totalRows={totalRows}
                            handlePageChange={handlePageChange}
                            handlePerRowsChange={handlePerRowsChange}
                        />
                    </>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) } >
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <CreateOrderFromScratchModal />
                </ModalActions>
            </Modal>
        </>
    );
};

export default CreateOrderFromProspectModal;
