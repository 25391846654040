import React, { useState } from "react";
import { Container, Label, MenuItem, Tab } from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import InTracingRequiredListTab from "./tabs/InTracingRequiredListTab";
import InTracingAttendedListTab from "./tabs/InTracingAttendedListTab";

const InTracingTabs = () => {

    const [ itemsRequired, setItemsRequired ] = useState(0);
    //const [ itemsAttended, setItemsAttended ] = useState(0);

    const panes = [
        { menuItem: (<MenuItem key='messages'>Requiren atención<Label color="red">{itemsRequired}</Label></MenuItem>), render: () => (
            <InTracingRequiredListTab setTotalItems={setItemsRequired} />
        ) },
        { menuItem: (<MenuItem key='messages'>Prospectos atendidos</MenuItem>), render: () => (
            <InTracingAttendedListTab />
        ) },
    ];

	return (
		<>
			<Navbar />
			<Container className="container-custom">
                <PageHeader title="En seguimiento" subtitle="Clientes potenciales" />
                <Tab panes={panes} />
			</Container>
		</>
	);
};

export default InTracingTabs;
