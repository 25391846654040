import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button, FormField, FormGroup, Header, Icon, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';
import LuxonDataTable from './LuxonDataTable';
import InputSearch from './InputSearch';
import Client from '../types/entities/Client';
import { getClientsByPhoneOrName } from '../services/clients.service';
import { toast } from 'react-toastify';

interface Props {
    disabled: boolean
    onClientSelected: (clientId: string) => void
}

const SearchClientModal = (props: Props) => {

    const [ loading, setLoading ] = useState(false);
    const [ openModal, setOpenModal ] = useState(false);

    const [ perPage, setPerPage ] = useState(10);
    const [ totalRows, setTotalRows ] = useState(0);
    const [ , setCurrentPage ] = useState(1);
    const [ data, setData ] = useState<Client[]>([]);
    
    const [ searchByFullName, setSearchByFullName ] = useState("");
    const [ searchByPhone, setSearchByPhone ] = useState("");
    
    const columns = [
        {
            name: "Nombre",
            selector: (row: Client) => row.person?.fullName,
        },
        {
            name: "Telefono",
            selector: (row: Client) => row.person?.phone,
        },
        {
            name: "Direccion",
            selector: (row: Client) => row.address?.street,
        },
        {
            name: "Acciones",
            button: true,
            style: { justifyContent: "right", paddingRight: "16px", paddingLeft: "16px" },
            cell: (row: Client) => (
                <>
                    <Button color='green' icon size="mini" onClick={ (event) => handleClientSelected(event, row.id) }>
                        <Icon name="plus circle" />
                    </Button>
                </>
            )
        },
    ];

    // Función para obtener los datos con parámetros
    const fetchData = async (page: number, rowsPerPage: number, fullName: string, phone: string) => {
        setLoading(true);
        try {
            if (!fullName && !phone) {
                resetDataTable();
                return;
            } else {
                const response = await getClientsByPhoneOrName(
                    rowsPerPage,
                    page - 1,
                    "createdAt,desc",
                    fullName,
                    phone
                );
                setData(response?.data.content);
                setTotalRows(response?.data.totalElements);
            }
        } catch (error) {
            toast.error("Error al obtener el listado de clientes");
        } finally {
            setLoading(false);
        }
    };

    // Handlers that call the fetchData method
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        fetchData(page, perPage, searchByFullName, searchByPhone);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
        fetchData(page, newPerPage, searchByFullName, searchByPhone);
    };

    useEffect(() => {
        fetchData(1, perPage, searchByFullName, searchByPhone);
        // eslint-disable-next-line
    }, [searchByFullName, searchByPhone]);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    const handleClientSelected = async (event: SyntheticEvent, clientId: string) => {
        event.preventDefault();
        props.onClientSelected(clientId);
        setOpenModal(false);
    };

    const resetDataTable = () => {
        setData([]);
        setTotalRows(0);
    };

    return (
        <>
            <Modal
                centered={false}
                closeOnDimmerClick
                closeIcon={true}
                size="large"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button icon disabled={props.disabled} floated="right" color="teal" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="search" /> Buscar cliente
                            </Button>
                        }
                        content='Buscador de clientes ya registrados'
                        position='bottom center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="search" content="Buscador de clientes" />
                <ModalContent>
                    <>
                        <div className="ui form">
                            <FormGroup widths={2}>
                                <FormField>
                                    <InputSearch 
                                        icon="phone" 
                                        placeholder="Búsqueda por telefono..." 
                                        setSearch={setSearchByPhone}
                                    />
                                </FormField>

                                <FormField>
                                    <InputSearch 
                                        icon="user" 
                                        placeholder="Búsqueda por nombre..." 
                                        setSearch={setSearchByFullName}
                                    />
                                </FormField>
                            </FormGroup>
                        </div>

                        <LuxonDataTable
                            columns={columns}
                            data={data}
                            loading={loading}
                            totalRows={totalRows}
                            handlePageChange={handlePageChange}
                            handlePerRowsChange={handlePerRowsChange}
                        />
                    </>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) } >
                        <Icon name="cancel" /> Cancelar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default SearchClientModal;
