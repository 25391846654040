import React, { SyntheticEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OrderRequestForm from "./forms/OrderRequestForm";
import OrderClientPersonForm from "./forms/OrderClientPersonForm";
import Order from "../../types/entities/Order";
import { toast } from "react-toastify";
import { getOrderById } from "../../services/requests.service";
import PageHeader from "../../components/PageHeader";
import OrderAddressForm from "./forms/OrderAddressForm";
import CompleteOrderModal from "./modals/CompleteOrderModal";
import { assignClientToOrder, updateOrderStatusToValidatingOrder } from "../../services/orders.service";
import SearchClientModal from "../../components/SearchClientModal";
import RootTemplate from "../../components/RootTemplate";
import { useAppContext } from "../../hooks/AppProvider";

const OrdersForm = () => {
    
    const { updateParentState } = useAppContext();
    const [ order, setOrder ] = useState<Order | null>(null);
    const { orderId } = useParams();

    const fetchOrderById = async (id: string | undefined) => {
        try {
            const response = await getOrderById(id);
            if (response?.status === 200) {
                setOrder(response.data);
            } else {
                throw new Error("Error al obtener el pedido");
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const habldeCompleteOrder = async (event: SyntheticEvent, orderId: string | undefined) => {
        event.preventDefault();
        updateParentState({ loading: true });
        try {

            if (orderId === undefined) {
                throw new Error("El id del pedido no puede ser undefined.");
            }

            const response = await updateOrderStatusToValidatingOrder(orderId);
            if (response?.status === 200) {
                window.location.href = `/orders/${response?.data.id}/details`;
            } else {
                throw new Error("Ha ocurrido un error al marcar como completado el pedido.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    const hanldeClientSelected = async (clientId: string) => {
        updateParentState({ loading: true });
        try {
            if (!orderId) {
                throw new Error("No se ha seleccionado un pedido");
            }
            const response = await assignClientToOrder(orderId, clientId);
            if (response?.status === 200) {
                setOrder(response.data);
                toast.success("La informacion del cliente seleccionado ha sido asignada al pedido.");
            } else {
                throw new Error("Ha ocurrido un error al asignar el cliente al pedido.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    useEffect(() => {
        if (orderId !== undefined) {
            fetchOrderById(orderId);
        }
    }, [ orderId ]);

    return (
        <RootTemplate>
              
            <PageHeader
                title={order ? `Editar pedido #${order.invoice}` : "Crear nuevo pedido"}
                subtitle="El pedido se puede completar hasta toda la información del pedido haya sido ingresada."
                actionComponents={
                    <>
                        <CompleteOrderModal 
                            id={order?.id || ""} 
                            disabled={order?.address === null || order?.client?.person === null || order?.paymentProof === null}
                            submit={habldeCompleteOrder} 
                        />
                        <SearchClientModal 
                            disabled={order?.client?.id ? true : false} 
                            onClientSelected={hanldeClientSelected}
                        />
                    </>
                }
            /> 

            <OrderRequestForm
                order={order}
                setOrder={setOrder}
            />

            { order && (
                <>
                    <OrderClientPersonForm 
                        order={order} 
                        setOrder={setOrder}
                    />

                    <OrderAddressForm 
                        orderId={order?.id} 
                        order={order}
                        setOrder={setOrder}
                    />
                </>
            )}

        </RootTemplate>
    );
};

export default OrdersForm;
