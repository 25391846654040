import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Button, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup, Select, TextArea } from 'semantic-ui-react';
import { getProducts } from '../../../services/products.service';
import { getUsers } from '../../../services/users.service';
import { USER_ROLE_ADVISOR } from '../../../utils/constants';
import DatePicker from 'react-datepicker';
import { setHours, setMinutes } from 'date-fns';
import { createProspect } from '../../../services/prospects.service';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/AuthProvider';
import DropdownOption from '../../../types/models/DropdownOption';
import { PROSPECT_ORIGIN_OPTIONS } from '../../../utils/dropdownOptions';

const CreateProspectModal = () => {

    const auth = useAuth();
    const userLogged = auth.userData ?? null;
    const userIsAdvisor = userLogged?.role === USER_ROLE_ADVISOR;

    const [ openModal, setOpenModal ] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    const [ advisors, setAdvisors ] = useState<DropdownOption[]>([]);
    const [ products, setProducts ] = useState<DropdownOption[]>([]);
    const [ requestData, setRequestData ] = useState({
        origin: "",
        advisorId: userIsAdvisor ? userLogged?.id : "",
        productId: "",
        appointment: "",
        notes: "",
    });
    const [ appointmentDate, setAppointmentDate ] = useState<Date | null>(null);

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value,
        });
    };

    const fetchAdvisors = useCallback(async () => {
        try {
            if (userIsAdvisor) {
                const advisorsList = [{
                    key: userLogged?.id,
                    value: userLogged?.id,
                    text: userLogged?.person?.fullName ?? "Sin nombre",
                }];
                setAdvisors(advisorsList);
            } else {
                const response = await getUsers(100, 0, USER_ROLE_ADVISOR);
                if (!response?.data.content) {
                    return;
                }
                const advisorsList = response?.data.content.map(
                    (advisor: any) => {
                        return {
                            key: advisor.id,
                            value: advisor.id,
                            text: advisor.person?.fullName ?? "Sin nombre",
                        };
                    }
                );
                setAdvisors(advisorsList);
            }
        } catch (error) {
            toast.error("Error al obtener la lista de asesores");
        }
    }, [userIsAdvisor, userLogged, setAdvisors]);

    const fetchProducts = async () => {
        try {
            const response = await getProducts(100, 0);
            const productsList = response?.data.content.map(
                (product: any) => {
                    return {
                        key: product["id"],
                        value: product["id"],
                        text: `${product["sku"]} - ${product["name"]}`,
                    };
                }
            );
            setProducts(productsList);
        } catch (error) {
            console.error("Error al obtener la lista de asesores:", error);
        }
    };

    useEffect(() => {
        if (openModal) {
            fetchAdvisors();
            fetchProducts();
        }
    }, [fetchAdvisors, openModal]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {

            if (!appointmentDate) {
                throw new Error("Favor de seleccionar una fecha de la cita");
            } else {
                requestData.appointment = appointmentDate.toISOString();
            }

            const response = await createProspect(requestData);

            if (response?.status === 201) {
                window.location.href = `/prospects/${response.data.id}/form`;
            } else {
                throw new Error("El prospecto no ha sido creado exitosamente, favor de contactar al area de TI");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button floated='right' color="blue" onClick={ (event) => handleOpenModal(event) }>
                                Nuevo prospecto
                            </Button>
                        }
                        content='Realizar seguimiento'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="calendar" content="Nuevo prospecto" />
                <ModalContent>
                    <div className="ui form">

                        <FormField
                            control={Select}
                            label={<label htmlFor="form-select-control-origin">Canal de origen</label>}
                            options={PROSPECT_ORIGIN_OPTIONS}
                            placeholder="Selecciona una opcion"
                            name="origin"
                            value={requestData.origin}
                            onChange={handleChange}
                            required
                            search
                            searchInput={{id: "form-select-control-origin"}}
                            clearable
                        />

                        { !userIsAdvisor && (
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-advisor">Asesor</label>}
                                options={advisors}
                                placeholder="Selecciona una opcion"
                                name="advisorId"
                                value={requestData.advisorId}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-advisor"}}
                                clearable
                            />
                        )}

                        <FormField
                            control={Select}
                            label={<label htmlFor="form-select-control-advisor">Producto de interes</label>}
                            options={products}
                            placeholder="Selecciona una opcion"
                            name="productId"
                            value={requestData.productId}
                            onChange={handleChange}
                            required
                            search
                            searchInput={{id: "form-select-control-advisor"}}
                            clearable
                        />

                        <FormField required>
                            <label>Fecha y hora de visita</label>
                            <DatePicker
                                showTimeSelect
                                isClearable
                                minDate={new Date()}
                                minTime={setHours(setMinutes(new Date(), 59),7)}
                                maxTime={setHours(setMinutes(new Date(), 59),21)}
                                dateFormat="d-MM-yyyy h:mm aa"
                                selected={appointmentDate}
                                placeholderText="Fecha de instalación"
                                onChange={(date: Date | null) =>setAppointmentDate(date)}
                                wrapperClassName="date-picker-custom-parent"
                                className="date-picker-custom"
                            />
                        </FormField>

                        <FormField
                            control={TextArea}
                            label="Notas adicionales"
                            placeholder="Añade notas adicionales para el prospecto..."
                            name="notes"
                            value={requestData.notes}
                            onChange={ handleChange }
                        />
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (e) => handleSubmit(e) } loading={isSubmitting} >
                        <Icon name="checkmark" /> Guardar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default CreateProspectModal;
