import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './hooks/AuthProvider';
import LuxonRoutes from './routes/LuxonRoutes';
import PrivateRoute from './routes/PrivateRoute';
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppProvider } from './hooks/AppProvider';

const App = () => {
    return (
		<>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable={false}
				pauseOnHover
				theme="light"
				transition={Bounce}
			/>
			<BrowserRouter>
				<AuthProvider>
					<AppProvider>
						<LuxonRoutes element={<PrivateRoute />} />
					</AppProvider>
				</AuthProvider>
			</BrowserRouter>
		</>
    );
};

export default App;
