import React, { SyntheticEvent, useState } from 'react';
import { Button, FormField, Header, Icon, Input, Modal, ModalActions, ModalContent, Popup, TextArea } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { createGuaranteeAdjustment } from '../../../services/guarantees.service';

interface RequestData {
    cause?: string;
    moneyRemaining?: string;
}

const CreateAdjustmentModal = () => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    
    const [ requestData, setRequestData ] = useState<RequestData>({
        cause: "",
        moneyRemaining: "",
    });

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        try {
            // clean remaining money after creating the order
            requestData.moneyRemaining = requestData.moneyRemaining?.replace(/[^0-9.,]/g, '').replace(/,/g, '');

            const response = await createGuaranteeAdjustment(requestData);

            if (response?.status === 201) {
                window.location.href = `/guarantees/adjustments/${response.data.id}/form`;
            } else {
                throw new Error("El ajuste no ha sido creado exitosamente, favor de contactar al area de TI");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value ?? "",
        } as RequestData);
    };

    const handleChangeMoneyRemaining = (e: any, { name, value }: any) => {
        const newValue = value.replace(/[^0-9.,]/g, '').replace(/,/g, '');
        if (newValue.length <= 10) {
            setRequestData({
                ...requestData,
                [name]: formatMoney(newValue) ?? "",
            } as RequestData);
        }
    };

    // this method receives a string of numbers and returns a formatted string
    const formatMoney = (value: string | undefined) => {
        if (!value) {
            return "";
        }
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button icon floated='right' color="green" onClick={ (event) => handleOpenModal(event) }>
                                <Icon name="plus circle" /> Crear garantia de ajuste
                            </Button>
                        }
                        content='Crear nueva garantia del tipo "Ajuste"'
                        position='bottom center'
                        size='small'
                        inverted
                    />
                    
                } >
                <Header icon="plus circle" content="Crear nueva garantia" />
                <ModalContent>
                    <>
                        <div className="ui form">
                            <FormField
                                control={Input}
                                label={<label htmlFor="form-select-control-installer">Saldo por liquidar</label>}
                                placeholder="Si no hay saldo pendiente, poner 0"
                                name="moneyRemaining"
                                value={requestData.moneyRemaining}
                                onChange={handleChangeMoneyRemaining}
                                required
                            />

                            <FormField
                                clearable
                                control={TextArea}
                                label={<label htmlFor="form-select-control-payment">Causa de la garantia</label>}
                                placeholder="Selecciona una opcion"
                                name="cause"
                                value={requestData.cause}
                                onChange={handleChange}                                                            
                                required
                            />
                        </div>
                    </>
                </ModalContent>
                <ModalActions>
                    <Button icon onClick={ (event) => handleCancelModal(event) } >
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" icon
                        loading={loading}
                        onClick={ (event) => handleSubmit(event) } >
                        <Icon name="check" /> Crear ajuste
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default CreateAdjustmentModal;
