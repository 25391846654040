import React, { useEffect, useState } from "react";
import { Button, Form, FormField, FormGroup, Grid, GridColumn, Header, Input, Segment, Select } from "semantic-ui-react";
import { getNeighborhoodsByZipCode } from "../../../services/sepomex.service";
import { toast } from "react-toastify";
import Order from "../../../types/entities/Order";
import { createOrderAddress, updateOrderAddress } from "../../../services/orders.service";

interface Props {
    orderId: string | undefined;
    order: Order | null;
    setOrder: (order: Order | null) => void;
}

interface RequestData {
    street?: string;
    interiorNumber?: string;
    exteriorNumber?: string;
    postalCode?: string;
    city?: string;
    municipality?: string;
    state?: string;
    neighborhood?: string;
}

const OrderAddressForm = ({ orderId, order, setOrder }: Props) => {

    const [ loading, setLoading ] = useState(false);
    const [ requestData, setRequestData ] = useState<RequestData | undefined>(undefined);
    const [ neighborhoods, setNeighborhoods ] = useState([]);

    useEffect(() => {
        if (order?.address?.postalCode) {
            fetchNeighborhoods(order.address.postalCode);
        }
        setRequestData({
            street: order?.address?.street || "",
            interiorNumber: order?.address?.interiorNumber || "",
            exteriorNumber: order?.address?.exteriorNumber || "",
            postalCode: order?.address?.postalCode || "",
            city: order?.address?.city || "",
            municipality: order?.address?.municipality || "",
            state: order?.address?.state || "",
            neighborhood: order?.address?.neighborhood || ""
        });
    }, [ order ]);

    const fetchNeighborhoods = async (zipCode: string) => {
        setLoading(true);
        try {
            if (zipCode.length === 5) {
                const response = await getNeighborhoodsByZipCode(zipCode);
                if (response?.status === 200) {    
                    const neighborhoodsList = response.data.zip_codes.map(
                        (neighborhood: any) => {
                            return {
                                key: neighborhood["d_asenta"],
                                value: neighborhood["d_asenta"],
                                text: neighborhood["d_asenta"],
                            };
                        }
                    );
                    setNeighborhoods(neighborhoodsList);
                }
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    }

    const handleChange = async (e: any, { name, value }: any) => {
        
        if (name === "postalCode" && value.length === 5) {
            setLoading(true);
            try {
                const response = await getNeighborhoodsByZipCode(value);
                if (response?.status === 200) {
                    const itemZero = response.data.zip_codes[0];

                    const neighborhoodsList = response.data.zip_codes.map(
                        (neighborhood: any) => {
                            return {
                                key: neighborhood["d_asenta"],
                                value: neighborhood["d_asenta"],
                                text: neighborhood["d_asenta"],
                            };
                        }
                    );
                    setNeighborhoods(neighborhoodsList);

                    setRequestData({
                        ...requestData,
                        postalCode: value ?? "",
                        city: itemZero.d_ciudad,
                        municipality: itemZero.d_mnpio,
                        state: itemZero.d_estado,
                    } as RequestData);
                } else {
                    throw new Error("El código postal seleccionado no es valido.");
                }
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                setLoading(false);
            }
        } else {
            setRequestData({
                ...requestData,
                [name]: value ?? "",
            } as RequestData);
        }

    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        try {

            if (!orderId) {
                throw new Error("El pedido no puede ser undefined.");
            }

            if (order?.address?.id) {
                const response = await updateOrderAddress(orderId, order.address.id, requestData);

                if (response?.status === 200) {
                    setOrder(response.data);
                    toast.success("La dirección del pedido ha sido actualizada con exito.");
                } else {
                    throw new Error("El pedido no ha sido actualizado exitosamente, favor de contactar al area de TI");
                }
            } else {
                const response = await createOrderAddress(orderId, requestData);

                if (response?.status === 201) {
                    setOrder(response.data);
                    toast.success("La dirección del pedido ha sido guardada con exito.");
                } else {
                    throw new Error("El pedido no ha sido creado exitosamente, favor de contactar al area de TI");
                }
            }
            
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid>
                <GridColumn>
                    <Segment attached='top' color="grey" style={{ marginTop: 15 }}>
                        <Grid>
                            <GridColumn style={{ paddingTop: 5, paddingBottom: 5 }} verticalAlign="middle">
                                <Header as='h4' floated="left" style={{ marginTop: 8, marginBottom: 7 }}>Domicilio</Header>
                                { order?.address?.id ? (
                                    <Button floated="right" color="yellow" type="submit" style={{ margin: 0 }} disabled={loading}>Actualizar</Button>
                                ): (
                                    <Button floated="right" primary type="submit" style={{ margin: 0 }} disabled={loading}>Guardar</Button>
                                )}  
                            </GridColumn>
                        </Grid>
                    </Segment>
                    <Segment attached='bottom' loading={loading}>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Input}
                                label='Calle'
                                placeholder='Nombre de la calle'
                                name="street"
                                value={requestData?.street}
                                onChange={handleChange}
                                required
                            />
                            <FormField
                                control={Input}
                                label='Numero exterior'
                                placeholder='Numero exterior'
                                name="exteriorNumber"
                                value={requestData?.exteriorNumber}
                                onChange={handleChange}
                                required
                            />
                            <FormField
                                control={Input}
                                label='Numero interior'
                                placeholder='Numero interior'
                                name="interiorNumber"
                                value={requestData?.interiorNumber}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Input}
                                label='Codigo postal'
                                placeholder='Nombre de la calle'
                                name="postalCode"
                                value={requestData?.postalCode}
                                onChange={handleChange}
                                required
                            />

                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-neighborhood">Colonia</label>}
                                options={neighborhoods}
                                placeholder="Selecciona una opción"
                                name="neighborhood"
                                value={requestData?.neighborhood}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-neighborhood"}}
                                clearable
                            />
                        </FormGroup>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Input}
                                label='Ciudad'
                                placeholder='Ciudad'
                                name="city"
                                value={requestData?.city}
                                required
                            />
                            <FormField
                                control={Input}
                                label='Municipio'
                                placeholder='Municipio'
                                name="municipality"
                                value={requestData?.municipality}
                                required
                            />
                            <FormField
                                control={Input}
                                label='Estado'
                                placeholder='Estado'
                                name="state"
                                value={requestData?.state}
                                required
                                readonly
                            />
                        </FormGroup>
                    </Segment>
                </GridColumn>
            </Grid>
        </Form>
    );
};

export default OrderAddressForm;
