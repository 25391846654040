import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Button, FormField, Header, Icon, Input, Modal, ModalActions, ModalContent, Select } from 'semantic-ui-react';
import { getUsers } from '../../../services/users.service';
import { toast } from 'react-toastify';
import { USER_ROLE_ADVISOR } from '../../../utils/constants';
import DropdownOption from '../../../types/models/DropdownOption';
import { getProducts } from '../../../services/products.service';
import { createOrder } from '../../../services/orders.service';

interface RequestData {
    advisorId?: string;
    productId?: string;
    moneyRemaining?: string;
}

const CreateOrderFromScratchModal = () => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const [ advisors, setAdvisors ] = useState<DropdownOption[]>([]);
    const [ products, setProducts ] = useState<DropdownOption[]>([]);
    
    const [ requestData, setRequestData ] = useState<RequestData>({
        advisorId: "",
        productId: "",
        moneyRemaining: "",
    });

    const handleChange = (e: any, { name, value }: any) => {
        setRequestData({
            ...requestData,
            [name]: value ?? "",
        } as RequestData);
    };

    const fetchAdvisors = useCallback(async () => {
        try {
            const response = await getUsers(100, 0, USER_ROLE_ADVISOR);
            if (!response?.data.content) {
                return;
            }
            const advisorsList = response?.data.content.map(
                (advisor: any) => {
                    return {
                        key: advisor.id,
                        value: advisor.id,
                        text: advisor.person?.fullName ?? "Sin nombre",
                    };
                }
            );
            setAdvisors(advisorsList);
        } catch (error) {
            toast.error("Error al obtener la lista de asesores");
        }
    }, [setAdvisors]);

    const fetchProducts = async () => {
        try {
            const response = await getProducts(100, 0);
            const productsList = response?.data.content.map(
                (product: any) => {
                    return {
                        key: product["id"],
                        value: product["id"],
                        text: `${product["sku"]} - ${product["name"]}`,
                    };
                }
            );
            setProducts(productsList);
        } catch (error) {
            console.error("Error al obtener la lista de asesores:", error);
        }
    };

    useEffect(() => {
        if (openModal) {
            fetchAdvisors();
            fetchProducts();
        }
    }, [fetchAdvisors, openModal]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        try {
            // clean remaining money after creating the order
            requestData.moneyRemaining = requestData.moneyRemaining?.replace(/[^0-9.,]/g, '').replace(/,/g, '');

            const response = await createOrder(requestData);

            if (response?.status === 201) {
                window.location.href = `/orders/${response.data.id}/form`;
            } else {
                throw new Error("El pedido no ha sido creado exitosamente, favor de contactar al area de TI");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeMoneyRemaining = (e: any, { name, value }: any) => {
        const newValue = value.replace(/[^0-9.,]/g, '').replace(/,/g, '');
        if (newValue.length <= 10) {
            setRequestData({
                ...requestData,
                [name]: formatMoney(newValue) ?? "",
            } as RequestData);
        }
    };

    // this method receives a string of numbers and returns a formatted string
    const formatMoney = (value: string | undefined) => {
        if (!value) {
            return "";
        }
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Button floated='right' color="green" onClick={ (event) => handleOpenModal(event) }>
                        <Icon name="plus circle" /> Crear pedido desde cero
                    </Button>
                } >
                <Header icon="plus circle" content="Crear nuevo pedido" />
                <ModalContent>
                    <>
                        <div className="ui form">
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-advisor">Asesor</label>}
                                options={advisors}
                                placeholder="Selecciona una opcion"
                                name="advisorId"
                                value={requestData.advisorId}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-advisor"}}
                                clearable
                            />
                            <FormField
                                control={Select}
                                label={<label htmlFor="form-select-control-advisor">Producto</label>}
                                options={products}
                                placeholder="Selecciona una opcion"
                                name="productId"
                                value={requestData.productId}
                                onChange={handleChange}
                                required
                                search
                                searchInput={{id: "form-select-control-advisor"}}
                                clearable
                            />
                            <FormField
                                control={Input}
                                label="Saldo por liquidar"
                                placeholder="0.00"
                                name="moneyRemaining"
                                value={requestData?.moneyRemaining}
                                onChange={handleChangeMoneyRemaining}
                                required
                            />
                        </div>
                    </>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) } >
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" href="/orders/form" 
                        loading={loading}
                        onClick={ (event) => handleSubmit(event) } >
                        <Icon name="plus circle" /> Crear pedido de cero
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default CreateOrderFromScratchModal;
