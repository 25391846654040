import axios from 'axios';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_AUTH_LOGIN_URL } from '../utils/constants';
import { getLocalToken, getLocalUser, setLocalToken, setLocalUser } from '../utils/helpers';
import User from '../types/entities/User';

type Props = {
    children?: ReactNode;
}

type IAuthContext = {
    accessToken: string | null;
    userData: User | null;
    login: (username: string, password: string) => void;
    logout: () => void;
}

const initialValue = {
    accessToken: null,
    userData: null,
    login: () => {},
    logout: () => {},
}

const AuthContext = createContext<IAuthContext>(initialValue);

const AuthProvider = ({ children }: Props) => {

    const [accessToken, setAccessToken] = useState(getLocalToken() || null);
    const [userData, setUserData] = useState(getLocalUser() || null);
    const navigate = useNavigate();

    const login = async (username: string, password: string): Promise<number | void> => {
        try {
            const response = await axios.post(API_AUTH_LOGIN_URL,
                { username, password },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
    
            if (response?.status === 200) {
                setLocalToken(response?.data.token);
                setLocalUser(response?.data.user);
                setAccessToken(response?.data.token);
                setUserData(response?.data.user);
                navigate("/");
            }

            return response?.status;
        } catch (error) {
            throw new Error("Nombre de usuario o contraseña incorrectos");
        }
    };
    
    const logout = () => {
        localStorage.removeItem('access_token');
        setAccessToken(null);
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{ accessToken, userData, login, logout }}>
            { children }
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
