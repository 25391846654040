import React, { SyntheticEvent, useState } from 'react';
import { Button, Checkbox, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';

interface CompleteOrderModalProps {
    id: string;
    disabled?: boolean;
    submit: (event: SyntheticEvent, id: string | undefined) => void;
}

const CompleteOrderModal = (props: CompleteOrderModalProps) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ orderCompleted, setOrderCompleted ] = useState(false);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>
            
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="green" floated='right' icon onClick={ (event) => handleOpenModal(event) }
                                disabled={props.disabled} >
                                <Icon name="check"/> Completar pedido
                            </Button>
                        }
                        content='Completar y enviar a Validando pedido'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="check square" content="Completar pedido" />
                <ModalContent>
                    <p> Estas seguro de que deseas completar este pedido, una vez completado no se podra volver a editar. Esta accion no se puede deshacer.</p>

                    <div className="ui form">
                        <FormField>
                            <Checkbox
                                label="Confirmo que el pedido ha sido completo."
                                checked={orderCompleted}
                                onChange={ (event, data) => setOrderCompleted(orderCompleted ? false : true) }
                            />
                        </FormField>
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => props.submit(event, props.id) } disabled={!orderCompleted}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default CompleteOrderModal;
