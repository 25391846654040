import React, { useEffect, useState } from "react";
import {
	Container,
	Header,
} from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import "./styles.css";
import UserAccountForm from "./forms/UserAccountForm";
import { useParams } from "react-router-dom";
import User from "../../types/entities/User";
import { getUserById } from "../../services/users.service";
import UserPersonForm from "./forms/UserPersonForm";

const UsersForm = () => {

	const [ user, setUser ] = useState<User | null>(null);

	const { userId } = useParams();

	const fetchUserById = async (id: string | undefined) => {
        try {

			if (!id) {
				throw new Error("El prospecto no puede ser undefined.");
			}

            const response = await getUserById(id);
            if (response?.status === 200) {
                setUser(response.data);
            } else {
                throw new Error("Error al obtener el usuario");
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchUserById(userId);
    }, [userId]);


	return (
		<>
			<Navbar />
			<Container style={{ marginTop: "7em" }}>
		
				<Header as="h1">Formulario de usuario</Header>

				<UserAccountForm 
					userId={user?.id}
					user={user}
					setUser={setUser}
				/>

				<UserPersonForm 
					userId={user?.id}
					user={user}
					setUser={setUser}
				/>

			</Container>
		</>
	);
};

export default UsersForm;
