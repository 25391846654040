import axios from "axios";
import { API_PROSPECTS_URL } from "../utils/constants";

export const getProspects = async (size: number, page: number, search?: string, sort: string = "createdAt,desc") => {
    const token = localStorage.getItem("access_token");
    try {
        const searchParam = search ? `&search=${search}` : "";
        const response = await axios.get(
            `${API_PROSPECTS_URL}?page=${page}&size=${size}&sort=${sort}${searchParam}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getProspectById = async (prospectId: string | undefined) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(
            `${API_PROSPECTS_URL}/${prospectId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getAssignments = async (size: number, page: number, search?: string, sort: string = "createdAt,desc") => {
    const token = localStorage.getItem("access_token");
    try {
        let url = `${API_PROSPECTS_URL}/advisor/assigned`;
        url += `?page=${page}&size=${size}&sort=${sort}`;
        url += search ? `&search=${search}` : "";
        const response = await axios.get(
            `${url}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getInTracingAttended = async (size: number, page: number, search?: string, sort: string = "createdAt,desc") => {
    const token = localStorage.getItem("access_token");
    try {
        let url = `${API_PROSPECTS_URL}/tracing/attended`;
        url += `?page=${page}&size=${size}&sort=${sort}`;
        url += search ? `&search=${search}` : "";
        const response = await axios.get(
            `${url}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getInTracingRequired = async (size: number, page: number, search?: string, sort: string = "createdAt,desc") => {
    const token = localStorage.getItem("access_token");
    try {
        let url = `${API_PROSPECTS_URL}/tracing/required`;
        url += `?page=${page}&size=${size}&sort=${sort}`;
        url += search ? `&search=${search}` : "";
        const response = await axios.get(
            `${url}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getCompletedProspects = async (size: number, page: number, sort: string = "createdAt,desc", search?: string, saleStatus?: string) => {
    const token = localStorage.getItem("access_token");
    try {
        let url = `${API_PROSPECTS_URL}/completed?page=${page}&size=${size}&sort=${sort}`;
        url += search ? `&search=${search}` : "";
        url += saleStatus ? `&saleStatus=${saleStatus}` : "";
        const response = await axios.get(
            `${url}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getCompletedProspectsForOrder = async (
    size: number, 
    page: number, 
    sort: string = "createdAt,desc", 
    searchByFullName?: string,
    searchByPhone?: string
) => {
    const token = localStorage.getItem("access_token");
    try {
        let url = `${API_PROSPECTS_URL}/completed-to-order?page=${page}&size=${size}&sort=${sort}`;
        url += searchByFullName ? `&searchByFullName=${searchByFullName}` : "";
        url += searchByPhone ? `&searchByPhone=${searchByPhone}` : "";
        const response = await axios.get(
            `${url}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const createProspect = async (payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(API_PROSPECTS_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const updateProspect = async (prospectId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_PROSPECTS_URL}/${prospectId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const createProspectPerson = async (prospectId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_PROSPECTS_URL}/${prospectId}/persons`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const updateProspectPerson = async (prospectId: string, personId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_PROSPECTS_URL}/${prospectId}/persons/${personId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const createProspectAddress = async (prospectId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_PROSPECTS_URL}/${prospectId}/address`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const updateProspectAddress = async (prospectId: string, personId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_PROSPECTS_URL}/${prospectId}/address/${personId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const deleteProspect = async (id: string | undefined) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.delete(
            `${API_PROSPECTS_URL}/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const rescheduleProspectById = async (id: string | undefined, newAppointmentDate: Date) => {
    const token = localStorage.getItem("access_token");
    try {
        const newAppointment = newAppointmentDate.toISOString();
        const url = `${API_PROSPECTS_URL}/${id}/reschedule?appointment=${newAppointment}`;
        const response = await axios.put(url, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const markProspectVisitedById = async (id: string | undefined) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_PROSPECTS_URL}/${id}/visited`;
        const response = await axios.put(url, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const cancelAppointmentById = async (id: string | undefined, cancellationCause: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_PROSPECTS_URL}/${id}/cancel-appointment`;
        const response = await axios.put(url, { 
            cancellationCause: cancellationCause
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const markProspectQuotedById = async (id: string | undefined) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_PROSPECTS_URL}/${id}/quoted`;
        const response = await axios.put(url, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const markProspectTrackedById = async (id: string | undefined, currentTraceNumber: number, tracingNotes: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_PROSPECTS_URL}/${id}/prospect-tracing`;
        const response = await axios.post(url, {
            traceNumber: currentTraceNumber,
            notes: tracingNotes
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const updateProspectSaleStatusById = async (id: string | undefined, saleStatus: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_PROSPECTS_URL}/${id}/sale-status?saleStatus=${saleStatus}`;
        const response = await axios.put(url, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const assignClientToProspect = async (id: string, clientId: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const url = `${API_PROSPECTS_URL}/${id}/assign-client/${clientId}`;
        const response = await axios.put(url, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
