import React, { useEffect, useState } from "react";
import { Button, Card, Container, Icon, Label } from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import { getOrders } from "../../services/requests.service";
import { ORDERS_STATUSES } from "../../utils/constants";
import Order from "../../types/entities/Order";
import LuxonDataTable from "../../components/LuxonDataTable";
import { daysPassedSince, formatDateTime } from "../../utils/datetimeUtils";
import InputSearch from "../../components/InputSearch";
import PageHeader from "../../components/PageHeader";
import StatusFilters from "../../components/StatusFilters";
import StatusFiltersOption from "../../types/models/StatusFiltersOption";

const OrdersList = () => {
	const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [search, setSearch] = useState("");
    const [statusFilters, setStatusFilters] = useState<string[]>([]);

	const columns = [
        {
            name: "Folio",
            button: true,
            selector: (row: Order) => <Label horizontal>{`#${row.invoice}`}</Label>,
        },
        {
            name: "Fecha de compra",
            selector: (row: Order) => formatDateTime(row.orderDate),
        },
        {
            name: "Cliente",
            selector: (row: Order) => row.client?.person?.fullName ?? "",
        },
        {
            name: "Producto",
            selector: (row: Order) => row.product.name,
        },
        {
            name: "Dias transcurridos",
            selector: (row: Order) => `${daysPassedSince(row.orderDate)} dias`,
        },
        {
            name: "Estatus",
            cell: (row: Order) => (
                <>
                    { row.status === null ? (
                        <Label color="olive">Pedido incompleto</Label>
                    ): (
                        <Label color={ORDERS_STATUSES[row.status].color}>{ORDERS_STATUSES[row.status].name}</Label>
                    )}
                </>
            ),
        },
        {
            name: "Acciones",
            button: true,
            cell: (row: Order) => (
                <>
                    <Button secondary icon size="mini" href={`orders/${row.id}/details`}>
                        <Icon name="search" />
                    </Button>
                </>
            ),
        },
    ];

	const fetchData = async (page: number, search?: string, status?: string[]) => {
        setLoading(true);
        try {
			const response = await getOrders(perPage, page-1, search, status, "createdAt,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page, search, statusFilters);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
			const response = await getOrders(newPerPage, page-1, "", statusFilters, "createdAt,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }        
    };

    useEffect(() => {
        fetchData(1);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData(1, search, statusFilters);
        // eslint-disable-next-line
    }, [search, statusFilters]);

    const statusList: StatusFiltersOption[] = [
        {
            name: "Validando pedido",
            statusKey: "VALIDANDO_PEDIDO",
            color: "yellow",
        },
        {
            name: "En produccion",
            statusKey: "EN_PRODUCCION",
            color: "grey",
        },
        {
            name: "Listo para agendar",
            statusKey: "LISTO_PARA_AGENDAR",
            color: "green",
        },
        {
            name: "Agendado",
            statusKey: "AGENDADO",
            color: "orange",
        },
        {
            name: "Proyecto terminado",
            statusKey: "PROYECTO_TERMINADO",
            color: "blue",
        },
        {
            name: "Proyecto inconcluso",
            statusKey: "PROYECTO_INCONCLUSO",
            color: "brown",
        },
        {
            name: "Cancelado",
            statusKey: "CANCELADO",
            color: "red",
        },
    ]

	return (
		<>
			<Navbar />
			<Container className="container-custom">
                <PageHeader title="Ordenes" />
                <InputSearch icon="search" placeholder="Búsqueda por nombre de cliente..." setSearch={setSearch} />
                <StatusFilters options={statusList} setStatusFilters={setStatusFilters} />

				<Card fluid>
                    <LuxonDataTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Card>
			</Container>
		</>
	);
};

export default OrdersList;
