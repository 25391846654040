import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionContent,
    AccordionTitle,
    Button,
    Card,
    CardContent,
    CardDescription,
    CardGroup,
    CardHeader,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    Grid,
    GridColumn,
    GridRow,
    Header,
    HeaderSubheader,
    Icon,
    Modal,
    ModalActions,
    ModalContent,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "semantic-ui-react";
import OrderStatusSteps from "../../components/OrderStatusSteps";
import { useParams } from "react-router-dom";
import {
    cancelOrderById,
    getOrderById,
} from "../../services/requests.service";
import {
    ORDER_STATUS_AGENDADO,
    ORDER_STATUS_CANCELADO,
    ORDER_STATUS_EN_PRODUCCION,
    ORDER_STATUS_LISTO_PARA_AGENDAR,
    ORDER_STATUS_PROYECTO_INCONCLUSO,
    ORDER_STATUS_PROYECTO_TERMINADO,
    ORDER_STATUS_VALIDANDO_PEDIDO,
    USER_ROLE_ADMINISTRATOR,
    USER_ROLE_LOGISTIC,
    USER_ROLE_ORDERS,
} from "../../utils/constants";
import Order from "../../types/entities/Order";
import './styles.css';
import { formatDateTime } from "../../utils/datetimeUtils";
import { formatMoney } from "../../utils/numberUtils";
import { toast } from "react-toastify";
import UpdateStatusToProductionModal from "./modals/UpdateStatusToProductionModal";
import UpdateStatusToReadyToScheduleModal from "./modals/UpdateStatusToReadyToScheduleModal";
import UpdateStatusToScheduledModal from "./modals/UpdateStatusToScheduledModal";
import UpdateStatusToProjectFinishedModal from "./modals/UpdateStatusToProjectFinishedModal";
import UpdateStatusToProjectUnfinishedModal from "./modals/UpdateStatusToProjectUnfinishedModal";
import Guarantee from "../../types/entities/Guarantee";
import { getOrderGuaranteesByOrderId } from "../../services/orders.service";
import GuaranteeFilesModal from "../guarantees/modals/GuaranteeFilesModal";
import RootTemplate from "../../components/RootTemplate";
import { useAppContext } from "../../hooks/AppProvider";
import { useAuth } from "../../hooks/AuthProvider";

const OrdersDetail = () => {
    
    const { updateParentState } = useAppContext();

    const [firstModalOpen, setFirstModalOpen] = useState(false);

    const { userData } = useAuth();
    const userRole = userData?.role;

    const [order, setOrder] = useState<Order>();
    const [guarantees, setGuarantees] = useState<Guarantee[]>();
    const [openCancel, setOpenCancel] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // get the order id from params
    let { orderId } = useParams();

    useEffect(() => {
        const fetchOrder = async () => {
            updateParentState({ loading: true });
            try {
                const response = await getOrderById(orderId);

                if (response?.status === 200) {
                    const orderResponse: Order = response?.data;
                    setOrder(orderResponse);
                } else {
                    throw new Error("Something fails.");
                }
            } catch (error) {
                toast.error("Error al obtener el detalle del pedido.");
            } finally {
                updateParentState({ loading: false });
            }
        };
        fetchOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    useEffect(() => {
        const fetchGuarantees = async () => {
            try {
    
                if (!orderId) {
                    return;
                }
    
                const response = await getOrderGuaranteesByOrderId(100, 0, "createdAt,desc", orderId);
    
                if (response?.status === 200  && response?.data.content.length > 0) {
                    setGuarantees(response?.data.content);
                } 
            } catch (error) {
                toast.error("Error al obtener la lista de garantias.");
            }
        };
        fetchGuarantees();
    }, [order, orderId]);

    const onClickCancelOrder = async () => {
        setIsSubmitting(true);
        try {
            const response = await cancelOrderById(orderId);

            if (response?.status === 200) {
                setOrder(response?.data);
                setOpenCancel(false);
                toast.success("El pedido ha sido cancelado exitosamente.");
            } else {
                throw new Error("El pedido no ha podido ser cancelado.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleClick = (e: any, titleProps: any) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    const isUserRoleOrders = () => {
        if (userRole === USER_ROLE_ADMINISTRATOR || userRole === USER_ROLE_ORDERS) {
            return true;
        }
        return false;
    }

    const isUserRoleLogistic = () => {
        if (userRole === USER_ROLE_ADMINISTRATOR || userRole === USER_ROLE_LOGISTIC) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        if (order?.status === ORDER_STATUS_PROYECTO_INCONCLUSO) {
            console.log("proyecto inconcluso ahora si");
            if (guarantees && guarantees.length > 0) {
                if (guarantees[0].guaranteeFiles === null || guarantees[0].guaranteeFiles.length < 2) {
                    console.log("Debe de haber minimo 2 imagenes");
                    setFirstModalOpen(true);
                } 
            }
        }
    }, [order, guarantees]);

    return (
        <RootTemplate>
            <Grid>
                <GridRow>
                    <GridColumn>

                        <Header as="h1" floated="left">
                            Detalle del pedido
                            <HeaderSubheader>
                                Folio #{order?.invoice}
                            </HeaderSubheader>
                        </Header>

                        { order?.status === ORDER_STATUS_VALIDANDO_PEDIDO && isUserRoleOrders() && (
                            <UpdateStatusToProductionModal id={order?.id} setOrder={setOrder} />    
                        )}

                        { (order?.status === ORDER_STATUS_EN_PRODUCCION || order?.status === ORDER_STATUS_PROYECTO_INCONCLUSO)
                                && isUserRoleLogistic() && (
                            <UpdateStatusToReadyToScheduleModal id={order?.id} setOrder={setOrder} />    
                        )}

                        { order?.status === ORDER_STATUS_LISTO_PARA_AGENDAR && isUserRoleLogistic() && (
                            <UpdateStatusToScheduledModal id={order?.id} setOrder={setOrder} />    
                        )}

                        { order?.status === ORDER_STATUS_AGENDADO && isUserRoleLogistic() && (
                            <>
                                <UpdateStatusToProjectFinishedModal id={order?.id} setOrder={setOrder} />   
                                <UpdateStatusToProjectUnfinishedModal id={order?.id} setOrder={setOrder} />  
                            </>
                        )}

                        { order?.paymentProof &&
                            order?.status !== ORDER_STATUS_CANCELADO &&
                            order?.status !== ORDER_STATUS_PROYECTO_TERMINADO && (
                            <>
                                <Dropdown icon='ellipsis horizontal' floating floated="right" button className='icon'>
                                    <DropdownMenu>
                                        <DropdownItem text='Cancelar pedido' onClick={ () => setOpenCancel(true) } />
                                    </DropdownMenu>
                                </Dropdown>
                                <Modal
                                    closeOnDimmerClick
                                    closeIcon={true}
                                    size="mini"
                                    onClose={() => setOpenCancel(false)}
                                    onOpen={() => setOpenCancel(true)}
                                    open={openCancel}
                                >
                                    <Header
                                        icon="warning circle"
                                        content="Favor de validar acción"
                                    />
                                    <ModalContent>
                                        <p>
                                            Estas seguro que deseas cancelar este pedido?, una vez realizada esta accion ya no se podra
                                            volver a trabajar con este pedido.
                                        </p>
                                    </ModalContent>
                                    <ModalActions>
                                        <Button
                                            color="red"
                                            onClick={onClickCancelOrder}
                                            disabled={isSubmitting}
                                        >
                                            <Icon name="checkmark" />{" "}
                                            Cancelar de todos modos
                                        </Button>
                                    </ModalActions>
                                </Modal>
                            </>
                        )}
                        </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <OrderStatusSteps status={order?.status} />
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <Accordion fluid styled style={{ marginBottom: "1.2em" }}>
                            <AccordionTitle 
                                active={activeIndex === 0}
                                index={0}
                                onClick={handleClick}>
                                <Icon name="dropdown" /> Seguimiento del pedido
                            </AccordionTitle>
                            <AccordionContent active={activeIndex === 0}>
                                <Table striped>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHeaderCell>Descripción</TableHeaderCell>
                                            <TableHeaderCell>Fecha</TableHeaderCell>
                                        </TableRow>
                                    </TableHeader>

                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Fecha en que se realizo el pedido</TableCell>
                                            <TableCell>{ formatDateTime(order?.orderDate) }</TableCell>
                                        </TableRow>
                                        { order?.productionStartDate && (<TableRow>
                                            <TableCell>Fecha de inicio de producción</TableCell>
                                            <TableCell>{ formatDateTime(order?.productionStartDate) }</TableCell>
                                        </TableRow>) }
                                        { order?.productionEndDate && (<TableRow>
                                            <TableCell>Fecha de fin de producción</TableCell>
                                            <TableCell>{ formatDateTime(order?.productionEndDate) }</TableCell>
                                        </TableRow>) }
                                        { order?.installationDate && (<TableRow positive>
                                            <TableCell>Fecha de instalación agendada</TableCell>
                                            <TableCell>{ formatDateTime(new Date(order?.installationDate)) } </TableCell>
                                        </TableRow>) }
                                    </TableBody>
                                </Table>
                            </AccordionContent>
                        </Accordion>
                    </GridColumn>
                </GridRow>
            </Grid>

            <Card.Group itemsPerRow={3}>
                <Card>
                    <CardContent>
                        <CardHeader>Cliente</CardHeader>
                        <CardDescription>
                            <p>
                                <strong>Nombre:</strong>{" "}
                                { order?.client?.person?.fullName }
                            </p>
                            <p>
                                <strong>Telefono (WhatsApp):</strong>{" "}
                                { order?.client?.person?.phone }
                            </p>
                            <p>
                                <strong>Email:</strong> { order?.client?.person?.email }
                            </p>
                        </CardDescription>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <CardHeader>Asesor</CardHeader>
                        <CardDescription>
                            <p>
                                <strong>Nombre:</strong>{" "}
                                { order?.advisor?.person?.fullName }
                            </p>
                            <p>
                                <strong>Telefono:</strong>{" "}
                                {order?.advisor?.person?.phone}
                            </p>
                            <p>
                                <strong>Email:</strong>{" "}
                                {order?.advisor?.person?.email}
                            </p>
                        </CardDescription>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <CardHeader>Producto</CardHeader>
                        <CardDescription>
                            <p>
                                <strong>Codigo:</strong>{" "}
                                {order?.product.sku}
                            </p>
                            <p>
                                <strong>Nombre:</strong>{" "}
                                {order?.product.name}
                            </p>
                            <p>
                                <strong>Tiempo de produccion:</strong>{" "}
                                {`${order?.product.productionTimeMin} - ${order?.product.productionTimeMax} dias habiles`}
                            </p>
                        </CardDescription>
                    </CardContent>
                </Card>
            </Card.Group>

            <Card.Group itemsPerRow={2}>
                <Card>
                    <CardContent>
                        <CardHeader>
                            Información general del pedido
                        </CardHeader>
                        <CardDescription>
                            <p>
                                <strong>Fecha de compra:</strong>{" "}
                                { formatDateTime(order?.orderDate) }
                            </p>
                            <p>
                                <strong>Saldo por liquidar:</strong> $
                                { formatMoney(order?.moneyRemaining) }
                            </p>
                            <p>
                                <strong>Comprobante de pago: </strong>
                                {order?.paymentProof ? (
                                    <a href={order?.paymentProof} target="_blank" rel="noreferrer">Abrir comprobante</a>
                                ): (
                                    "No ha sido cargado aun"
                                )}
                                
                            </p>
                        </CardDescription>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <CardHeader>Dirección del pedido</CardHeader>
                        <CardDescription>
                            <p>
                                <strong>Dirección:</strong>{" "}
                                { `${order?.address?.street} ${order?.address?.exteriorNumber} ${order?.address?.interiorNumber}, ${order?.address?.neighborhood}` }
                            </p>
                            <p>
                                <strong>Codigo postal:</strong>{" "}
                                { order?.address?.postalCode }
                            </p>
                            <p>
                                <strong>Ciudad:</strong>{" "}
                                { `${order?.address?.city}, ${order?.address?.state}` }
                            </p>
                        </CardDescription>
                    </CardContent>
                </Card>
            </Card.Group>

            { order?.installationDate && (
                <Card fluid color="orange" style={{ marginTop: "25px" }}>
                    <CardContent>
                        <CardHeader>
                            Información de la instalación
                        </CardHeader>
                        <CardDescription>
                            <p>
                                <strong>Fecha de instalación:</strong>{" "}
                                { formatDateTime(new Date(order?.installationDate)) }
                            </p>
                            <p>
                                <strong>Instalador asignado:</strong>{" "}
                                { order?.installer?.person?.fullName }
                            </p>
                        </CardDescription>
                    </CardContent>
                </Card>
            )}

            <CardGroup itemsPerRow={3}>
            { guarantees && guarantees.length > 0 && (
                guarantees.map((guarantee: Guarantee, index: number) => (

                    <Card fluid color="brown" style={{ marginTop: "25px" }}>
                        <CardContent>
                            <CardHeader>
                                Garantia de { formatDateTime(new Date(guarantee?.createdAt)) }
                            </CardHeader>
                            <CardDescription>
                                <p>
                                    <strong>Nuevo saldo pendiente:</strong> $
                                    { formatMoney(guarantee?.moneyRemaining) }
                                </p>
                                <p>
                                    <strong>Comprobante de pago:</strong> {" "}
                                    <a href={guarantee?.proofPayment} target="_blank" rel="noreferrer">Abrir comprobante</a>
                                </p>
                                <p>
                                    <strong>Causa:</strong>{" "}
                                    { guarantee?.cause }
                                </p>
                            </CardDescription>
                        </CardContent>
                        <CardContent extra>
                            <GuaranteeFilesModal fluid guarantee={guarantee} isOpen={index === 0 ? firstModalOpen : false} />
                        </CardContent>
                    </Card>

                ))
            )}
            </CardGroup>

        </RootTemplate>
    );
};

export default OrdersDetail;
