import axios from "axios";
import { API_CLIENTS_URL } from "../utils/constants";

export const getClientsByPhoneOrName = async (
    size: number = 10,
    page: number = 0,
    sort: string = "createdAt,asc",
    searchByFullName?: string,
    searchByPhone?: string,
) => {
    const token = localStorage.getItem("access_token");
    try {
        const byFullNameParam = searchByFullName ? `&searchByFullName=${searchByFullName}` : "";
        const byPhoneParam = searchByPhone ? `&searchByPhone=${searchByPhone}` : "";
        const response = await axios.get(
            `${API_CLIENTS_URL}?page=${page}&size=${size}&sort=${sort}${byFullNameParam}${byPhoneParam}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
