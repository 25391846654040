import React, { useEffect, useState } from "react";
import { assignClientToProspect, getProspectById } from "../../services/prospects.service";
import { useParams } from "react-router-dom";
import Prospect from "../../types/entities/Prospect";
import ProspectPersonForm from "./forms/ProspectPersonForm";
import ProspectAddressForm from "./forms/ProspectAddressForm";
import ProspectAppointmentForm from "./forms/ProspectAppointmentForm";
import PageHeader from "../../components/PageHeader";
import SearchClientModal from "../../components/SearchClientModal";
import { toast } from "react-toastify";
import RootTemplate from "../../components/RootTemplate";
import { useAppContext } from "../../hooks/AppProvider";
import ProspectCompletedModal from "./modals/ProspectCompletedModal";

const ProspectsForm = () => {

    const { updateParentState } = useAppContext();
    const [ prospect, setProspect ] = useState<Prospect | null>(null);
    const { prospectId } = useParams();

    const [ prospectCompleted, setProspectCompleted ] = useState(false);

    const fetchProspectById = async (id: string | undefined) => {
        try {
            const response = await getProspectById(id);
            if (response?.status === 200) {
                setProspect(response.data);
            } else {
                throw new Error("Error al obtener el prospecto");
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchProspectById(prospectId);
    }, [prospectId]);

    const hanldeClientSelected = async (clientId: string) => {
        updateParentState({ loading: true });
        try {
            if (!prospectId) {
                throw new Error("No se ha seleccionado un prospecto");
            }
            const response = await assignClientToProspect(prospectId, clientId);
            if (response?.status === 200) {
                setProspect(response.data);
                toast.success("La informacion del cliente seleccionado ha sido asignada al prospecto.");
            } else {
                throw new Error("Ha ocurrido un error al asignar el cliente al prospecto.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            updateParentState({ loading: false });
        }
    };

    useEffect(() => {
        console.log("Epale");
        if (prospect && prospect.person && prospect.appointmentAddress) {
            console.log("Abre modal si hay un prospecto y tiene person y direccion")
            setProspectCompleted(true);
        }
    }, [prospect]);

    return (
        <RootTemplate>

            <PageHeader 
                title="Formulario de prospecto" 
                actionComponents={
                    <SearchClientModal 
                        disabled={prospect?.person?.id ? true : false} 
                        onClientSelected={hanldeClientSelected} 
                    />
                }
            />

            <ProspectCompletedModal 
                isOpen={prospectCompleted} 
                onClose={() => setProspectCompleted(false)}
            />

            <ProspectAppointmentForm
                prospectId={prospect?.id}
                prospect={prospect}
                setProspect={setProspect}
            />

            <ProspectPersonForm 
                prospectId={prospect?.id} 
                prospect={prospect} 
                setProspect={setProspect}
            />

            <ProspectAddressForm 
                prospectId={prospect?.id} 
                prospect={prospect}
                setProspect={setProspect}
            />

        </RootTemplate>
    );
};

export default ProspectsForm;
