import React, { SyntheticEvent, useState } from 'react';
import { Button, Checkbox, FormField, Header, Icon, Modal, ModalActions, ModalContent, Popup } from 'semantic-ui-react';

interface Props {
    id: string;
    disabled?: boolean;
    submit: (event: SyntheticEvent, id: string | undefined) => void
}

const DeleteOrderModal = (props: Props) => {

    const [ openModal, setOpenModal ] = useState(false);
    const [ confirm, setConfirm ] = useState(false);

    const handleOpenModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(true);
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
    };

    return (
        <>  
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal}
                trigger={
                    <Popup
                        trigger={
                            <Button color="red" icon size='mini'
                                onClick={ (event) => handleOpenModal(event) }
                                disabled={props.disabled} >
                                <Icon name="trash"/>
                            </Button>
                        }
                        content='Eliminar pedido'
                        position='top center'
                        size='small'
                        inverted
                    />
                } >
                <Header icon="trash" content="Eliminar pedido" />
                <ModalContent>
                    <p> Estas seguro de que deseas eliminar este pedido. Esta accion no se puede deshacer.</p>

                    <div className="ui form">
                        <FormField>
                            <Checkbox
                                label="Confirmo que quiero eliminar el pedido."
                                checked={confirm}
                                onChange={ (event, data) => setConfirm(confirm ? false : true) }
                            />
                        </FormField>
                    </div>
                </ModalContent>
                <ModalActions>
                    <Button onClick={ (event) => handleCancelModal(event) }>
                        <Icon name="cancel" /> Cancelar
                    </Button>
                    <Button color="green" onClick={ (event) => props.submit(event, props.id) } disabled={!confirm}>
                        <Icon name="checkmark" /> Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default DeleteOrderModal;
