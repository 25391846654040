import axios from "axios";
import { API_USERS_URL } from "../utils/constants";

export const getUsers = async (size: number, page: number, roles?: string, search?: string, sort: string = "username,asc") => {
    const token = localStorage.getItem("access_token");
    try {
        const roleParam = roles ? `&roles=${roles}` : "";
        const searchParam = search ? `&search=${search}` : "";
        const response = await axios.get(
            `${API_USERS_URL}?page=${page}&size=${size}&sort=${sort}${roleParam}${searchParam}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const getUserById = async (userId: string) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.get(
            `${API_USERS_URL}/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const createUser = async (payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(API_USERS_URL, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const updateUser = async (userId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_USERS_URL}/${userId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const createUserPerson = async (userId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.post(`${API_USERS_URL}/${userId}/person`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};

export const updateUserPerson = async (userId: string, personId: string, payload: any) => {
    const token = localStorage.getItem("access_token");
    try {
        const response = await axios.put(`${API_USERS_URL}/${userId}/person/${personId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error(error);
    }
};
