import React, { SyntheticEvent, useEffect, useState } from "react";
import { Button, Card, Container, Icon, Label } from "semantic-ui-react";
import Navbar from "../../components/Navbar";
import Prospect from "../../types/entities/Prospect";
import LuxonDataTable from "../../components/LuxonDataTable";
import { formatDateTime } from "../../utils/datetimeUtils";
import InputSearch from "../../components/InputSearch";
import PageHeader from "../../components/PageHeader";
import { cancelAppointmentById, getProspects, rescheduleProspectById } from "../../services/prospects.service";
import { PROSPECTS_STATUSES } from "../../utils/constants";
import CreateProspectModal from "./modals/CreateProspectModal";
import CancelAppointmentModal from "./modals/CancelAppointmentModal";
import { toast } from "react-toastify";
import ProspectDetailModal from "./modals/ProspectCancelationCauseModal";
import RescheduleAppointmentModal from "./modals/RescheduleAppointmentModal";

const OrdersList = () => {
	const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [search, setSearch] = useState("");

	const columns = [
        {
            name: "Fecha de visita",
            selector: (row: Prospect) => formatDateTime(row.appointment),
        },
        {
            name: "Nombre del prospecto",
            selector: (row: Prospect) => row.person?.fullName || '',
        },
        {
            name: "Producto",
            selector: (row: Prospect) => `(${row.product.sku}) ${row.product.name}`,
        },
        {
            name: "Asignado por",
            selector: (row: Prospect) => row.createdBy?.person?.fullName || '',
        },
        {
            name: "Estatus",
            cell: (row: Prospect) => (
                <>
                    { row.appointmentCanceled === true ? (
                        <Label color="red">Cita cancelada</Label>
                    ): (
                        <Label color={PROSPECTS_STATUSES[row.status].color}>{PROSPECTS_STATUSES[row.status].name}</Label>
                    )}
                </>
            ),
        },
        {
            name: "Acciones",
            button: true,
            cell: (row: Prospect) => (
                row.appointmentCanceled !== true ? (
                    <>
                        <CancelAppointmentModal id={row.id} submit={handleCancelationAppointment} />
                        <Button color="yellow" icon size="mini" href={`prospects/${row.id}/form`}><Icon name="pencil" /></Button>
                    </>
                ): (
                    <>
                        <ProspectDetailModal prospect={row} />
                        <RescheduleAppointmentModal id={row.id} submit={handleRescheduleAppointment} />
                    </>
                )
            ),
        },
    ];

    const handleCancelationAppointment = async (event: SyntheticEvent, prospectId: string | undefined, cancellationCause: string) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await cancelAppointmentById(prospectId, cancellationCause);
            if (response?.status === 200) {
                fetchData(1, search);
                toast.success("La cita fue cancelada con exito.");
            } else {
                throw new Error("Ha ocurrido un error al cancelar la cita para este prospecto.");
            }
        } catch (error: any) {
            toast.error(error.message);
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleRescheduleAppointment = async (event: SyntheticEvent, prospectId: string | undefined, appointmentDate: Date | null) => {
        event.preventDefault();
        setLoading(true);
        try {

            if (prospectId === undefined) {
                throw new Error("El id del prospecto no puede ser undefined.");
            }

            if (!appointmentDate) {
                throw new Error("Favor de seleccionar una fecha de la cita");
            }
            
            const response = await rescheduleProspectById(prospectId, appointmentDate);
            if (response?.status === 200) {
                fetchData(1, search);
                toast.success("La cita fue reagendada con exito.");
            } else {
                throw new Error("Ha ocurrido un error al mover el pedido a produccion.");
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

	const fetchData = async (page: number, search?: string) => {
        setLoading(true);
        try {
			const response = await getProspects(perPage, page-1, search, "createdAt,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        fetchData(page);
    };

    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setLoading(true);
        try {
			const response = await getProspects(newPerPage, page-1, "", "createdAt,desc");
            setData(response?.data.content);
            setTotalRows(response?.data.totalElements);
            setPerPage(newPerPage);
        } catch (error) {
            console.error("Error al obtener la informacion:", error);
        } finally {
            setLoading(false);
        }        
    };

    useEffect(() => {
        fetchData(1);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchData(1, search);
        // eslint-disable-next-line
    }, [search]);

	return (
		<>
			<Navbar />
			<Container className="container-custom">
                <PageHeader 
                    title="Prospectos" 
                    subtitle="Clientes potenciales"
                    actionComponents={ <CreateProspectModal /> } 
                />

                <InputSearch icon="search" placeholder="Búsqueda por nombre de prospecto..." setSearch={setSearch} />

				<Card fluid>
                    <LuxonDataTable
                        columns={columns}
                        data={data}
                        loading={loading}
                        totalRows={totalRows}
                        handlePageChange={handlePageChange}
                        handlePerRowsChange={handlePerRowsChange}
                    />
                </Card>
			</Container>
		</>
	);
};

export default OrdersList;
