import axios from "axios";

const SEPOMEX_API_URL = "https://sepomex.icalialabs.com/api/v1";
const SEPOMEX_API_ZIP_CODES_URL = SEPOMEX_API_URL + "/zip_codes";

export const getNeighborhoodsByZipCode = async (zipCode: string) => {
    try {
        const response = await axios.get(
            `${SEPOMEX_API_ZIP_CODES_URL}?zip_code=${zipCode}`,
            {
                headers: {}
            }
        );
        return response;
    } catch (error) {
        console.error(error);
    }
};
