import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Button, Form, FormField, FormGroup, Grid, GridColumn, Header, Input, Segment, Select } from "semantic-ui-react";
import { toast } from "react-toastify";
import { GENDER_OPTIONS } from "../../../utils/dropdownOptions";
import Guarantee from "../../../types/entities/Guarantee";
import { createGuaranteeClientPerson, updateGuaranteeClientPerson } from "../../../services/guarantees.service";

interface Props {
    guarantee: Guarantee | null;
    setGuarantee: (guarantee: Guarantee | null) => void;
}

interface RequestData {
    name?: string;
    paternalSurname?: string;
    maternalSurname?: string;
    gender?: string;
    birthdate?: Date | null;
    phone?: string;
    email?: string;
}

const GuaranteeClientPersonForm = ({ guarantee, setGuarantee }: Props) => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [ requestData, setRequestData ] = useState<RequestData | undefined>(undefined);

    useEffect(() => {
        setRequestData({
            name: guarantee?.client?.person?.name || "",
            paternalSurname: guarantee?.client?.person?.paternalSurname || "",
            maternalSurname: guarantee?.client?.person?.maternalSurname || "",
            gender: guarantee?.client?.person?.gender || "",
            birthdate: guarantee?.client?.person?.birthdate ? new Date(guarantee?.client?.person?.birthdate + "T00:00:00"): undefined,
            phone: guarantee?.client?.person?.phone || "",
            email: guarantee?.client?.person?.email || ""
        });
    }, [ guarantee ]);

    const handleChange = (e: any, { name, value }: any) => {
        if (name === "phone" && !validatePhone(value)) return;
        setRequestData({
            ...requestData,
            [name]: value ?? "",
        } as RequestData);
    };

    const validatePhone = (phone: string) => {
        const regex = /^[0-9]+$/;
        if (phone.length > 10) {
            return false;
        }
        if (phone !== "" && !regex.test(phone)) {
            return false;
        }
        return true;
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {

            if (!guarantee?.id) {
                throw new Error("El id del ajuste no puede ser undefined.");
            }

            if (requestData?.phone?.length !== 10) {
                throw new Error("El telefono no es valido, favor de verificar que sean 10 digitos sin espacios.");
            }

            if (guarantee?.client?.person?.id) {
                const response = await updateGuaranteeClientPerson(guarantee.id, guarantee.client.person.id, requestData);
                if (response?.status === 200) {
                    setGuarantee(response.data);
                    toast.success("Datos personales del ajuste han sido actualizados con exito");
                } else {
                    throw new Error("El ajuste no ha sido actualizado exitosamente, favor de contactar al area de TI");
                }
            } else {
                const response = await createGuaranteeClientPerson(guarantee.id, requestData);
                if (response?.status === 201) {
                    setGuarantee(response.data);
                    toast.success("Datos personales del ajuste han sido guardados con exito");
                } else {
                    throw new Error("El ajuste no ha sido creado exitosamente, favor de contactar al area de TI");
                }
            }
            
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Grid>
                <GridColumn>
                    <Segment attached='top' color="grey" style={{ marginTop: 15 }}>
                        <Grid>
                            <GridColumn style={{ paddingTop: 5, paddingBottom: 5 }} verticalAlign="middle">
                                <Header as='h4' floated="left" style={{ marginTop: 8, marginBottom: 7 }}>Datos personales</Header>
                                { guarantee?.client?.person?.id ? (
                                    <Button floated="right" color="yellow" type="submit" style={{ margin: 0 }}>Actualizar</Button>
                                ): (
                                    <Button floated="right" primary type="submit" style={{ margin: 0 }}>Guardar</Button>
                                )}  
                            </GridColumn>
                        </Grid>
                    </Segment>
                    <Segment attached='bottom' loading={isSubmitting}>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Input}
                                label='Nombre'
                                placeholder='Nombre(s)'
                                name="name"
                                value={requestData?.name}
                                onChange={handleChange}
                                required
                            />
                            <FormField
                                control={Input}
                                label='Apellido paterno'
                                placeholder='Apellido paterno'
                                name="paternalSurname"
                                value={requestData?.paternalSurname}
                                onChange={handleChange}
                                required
                            />
                            <FormField
                                control={Input}
                                label='Apellido materno'
                                placeholder='Apellido materno'
                                name="maternalSurname"
                                value={requestData?.maternalSurname}
                                onChange={handleChange}
                            />
                        </FormGroup>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Select}
                                label='Genero'
                                options={GENDER_OPTIONS}
                                placeholder='Selecciona una opción...'
                                name="gender"
                                value={requestData?.gender}
                                onChange={handleChange}
                                clearable
                                required
                            />
                            <FormField>
                                <label>Fecha de nacimiento</label>
                                <DatePicker
                                    isClearable
                                    dateFormat="d-MM-yyyy"
                                    placeholderText="Fecha de nacimiento"
                                    wrapperClassName="date-picker-custom-parent"
                                    className="date-picker-custom"
                                    name="birthdate"
                                    onChange={(date: Date | null, e) => handleChange(e, { name: "birthdate", value: date })}
                                    selected={requestData?.birthdate}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </FormField>
                        </FormGroup>
                        <FormGroup widths='equal'>
                            <FormField
                                control={Input}
                                label='Telefono'
                                placeholder='10 caracteres numericos sin espacios ni guiones'
                                name="phone"
                                value={requestData?.phone}
                                onChange={handleChange}
                                required
                            />
                            <FormField
                                control={Input}
                                label='Email'
                                placeholder='ejemplo@dominio.com'
                                name="email"
                                value={requestData?.email}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Segment>
                </GridColumn>
            </Grid>
        </Form>
    );
};

export default GuaranteeClientPersonForm;
