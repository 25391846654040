import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button, Header, Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import { USER_ROLE_ADVISOR, USER_ROLE_SOCIAL_MEDIA } from '../../../utils/constants';
import { toast } from 'react-toastify';
import { useAuth } from '../../../hooks/AuthProvider';

interface Props {
    isOpen?: boolean;
    onClose?: () => void;
}

const ProspectCompletedModal = ({ isOpen, onClose }: Props) => {

    const auth = useAuth();
    const [ openModal, setOpenModal ] = useState(isOpen);

    useEffect(() => {
        setOpenModal(isOpen);
    }, [isOpen]);

    const handleRedirect = async (event: any) => {
        event.preventDefault();
        if (auth.userData?.role === USER_ROLE_SOCIAL_MEDIA) {
            window.location.href = `/prospects`;
        } else if (auth.userData?.role === USER_ROLE_ADVISOR) {
            window.location.href = `/assignments`;
        } else {
            toast.error("La redirección ha fallado por alguna razón, contacta al area de soporte.");
        }
    };

    const handleCancelModal = (event: SyntheticEvent) => {
        event.preventDefault();
        setOpenModal(false);
        onClose && onClose();
    };

    return (
        <>
            <Modal
                closeOnDimmerClick
                closeIcon={true}
                size="small"
                onClose={(event) => handleCancelModal(event)}
                open={openModal} >
                <Header icon="check" content="Completar prospecto" />
                <ModalContent>
                    <p>
                        Parece que el prospecto ha sido completado, selecciona <b>Aceptar</b> si es asi o <b>Seguir editando</b> si aun no has terminado.
                    </p>
                </ModalContent>
                <ModalActions>
                    <Button color='orange' onClick={ (event) => handleCancelModal(event) }>
                        Seguir editando
                    </Button>
                    <Button color='green' onClick={ (event) => handleRedirect(event) }>
                        Aceptar
                    </Button>
                </ModalActions>
            </Modal>
        </>
    );
};

export default ProspectCompletedModal;
